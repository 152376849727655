import { defaultDataTransformer } from '@aspectus/resource-commons';
import { createFileSender } from '@md/resources';
import { prefixAPI } from '@md/api';

export const FILES_UPLOAD_URL = prefixAPI('/files/upload/');
export const FILES_UPLOAD_GOOGLE_URL = prefixAPI('/google-drive-files/upload/');

export const filesUploadResource = createFileSender(FILES_UPLOAD_URL)
  .transform(defaultDataTransformer);

export const filesUploadForGoogleResource = createFileSender(FILES_UPLOAD_GOOGLE_URL)
  .transform(defaultDataTransformer);
