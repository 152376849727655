<template>
  <resource-loader-controller :resource="listResource" v-slot="allProps">
    <resource-loader-controller :resource="availableResource" v-slot="availableProps">
      <search-filter
        :value="filters"
        :permanent="permanent"
        @input="changeFilters"
        :allProps="allProps"
        :availableProps="availableProps"
        v-bind="$attrs"
      />
    </resource-loader-controller>
  </resource-loader-controller>
</template>

<script>

import SearchFilter from './View';

export default {
  components: { SearchFilter },
  inheritAttrs: false,
  props: [
    'filters', 'availableResource', 'listResource', 'permanent',
  ],

  methods: {
    changeFilters(value) {
      this.$emit('update:filters', value);
    },
  },
};

</script>
