import Container from './Container';
import Line from './Line';
import Cell from './Cell';
import Fieldset from './Fieldset';
import Controller from './Controller';
import GlobalErrors from './GlobalErrors';

import Base from './Base';
import Submittable from './Submittable';

function install(Vue) {
  Vue.component('form-container', Container);
  Vue.component('form-line', Line);
  Vue.component('form-cell', Cell);
  Vue.component('form-fieldset', Fieldset);
  Vue.component('form-controller', Controller);
  Vue.component('form-global-errors', GlobalErrors);

  Vue.component('form-element-base', Base);
  Vue.component('form-element-submittable', Submittable);
}

export default { install };
