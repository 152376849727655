/*
  eslint-disable
    max-classes-per-file,
    prefer-spread,
    prefer-rest-params,
    class-methods-use-this,
    prefer-object-spread
*/

import { path, curry } from 'ramda';
import { Or } from '@aspectus/permissions';
import { accessMeta } from '@aspectus/vue-router-permissions';
import { checkAuth } from '@md/auth/permissions';
import {
  MAP, MANAGER_TYPE,
  OPERATIONS_MANAGER_TYPE,
  ASSISTANT_TYPE, STOCKMAN_TYPE,
  CLIENT_TYPE,
  CASHBOX_USER_TYPE,
  TRANSFER_USER_TYPE,
  ACCOUNTANT_USER_TYPE,
} from './accessTokens';

const userStateGetter = path(['state', 'users', 'auth']);

export function checkUser(checker, config = {}, ...args) {
  return checkAuth(
    checker, Object.assign({ getter: userStateGetter }, config), ...args
  );
}

export const isSuperuser = user => user.info.isSuperuser;
export const isType = curry((type, user) => user.info.profile.type === type);
export const isPermitted = curry(
  (permission, user) => !!(MAP[user.info.profile.type] && MAP[user.info.profile.type][permission])
);

export const toBe = type => checkUser(isType(type));
export const toBeAuthenticated = checkUser(user => user.isAuthenticated());
export const toBeSuperuser = checkUser(isSuperuser);
export const toBeClient = toBe(CLIENT_TYPE);
export const toBeManager = toBe(MANAGER_TYPE);
export const toBeCashboxType = toBe(CASHBOX_USER_TYPE);
export const toBeTransferType = toBe(TRANSFER_USER_TYPE);
export const toBeAccountant = toBe(ACCOUNTANT_USER_TYPE);
export const toBeOperationsManager = toBe(OPERATIONS_MANAGER_TYPE);
export const toBeAssistant = toBe(ASSISTANT_TYPE);
export const toBeStockman = toBe(STOCKMAN_TYPE);
export const toHasOwnPermission = permission => checkUser(isPermitted(permission));
export const toHasPermission = permission => new Or(
  toBeSuperuser, toHasOwnPermission(permission)
);

export const accessPermission = (permission, options) => accessMeta(
  toHasPermission(permission), { name: 'auth:login' }, options
);
