export function superagentJsonTransformer(response) {
  return response.body;
}

export function jsonTransformer(response) {
  return response.json();
}

export function defaultDataTransformer(result) {
  return result && result.data || result;
}

export function raiseHttpErrorTransformer(response) {
  if (!response.ok) {
    throw response;
  }

  return response;
}
