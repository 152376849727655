<script>

export default {
  name: 'ModalTrigger',
  props: {
    url: {
      type: String, 
      default: () => null
    },
    name: {
      type: String
    },
    classes: {
      type: Array
    },
    
    
  },
  methods: {
    openPopup() {
      this.$modal.show(this.componentLoader, 
        {
          info: this.$attrs
        }, {
          height: 'auto',
          name: this.name,
          minWidth: 300,
          classes: this.classes,
          adaptive: true,
          scrollable: true
        }, {
          'before-close': event => {
            this.close();
          }
        })
    },
    close() {
      this.$emit('close');
    }
  },
  computed: {

    componentLoader() { 
      return () => import(`@sections/${this.url}`)
    },
  },
  render(h) {
    return this.$scopedSlots.default({
      open: () => {
        this.openPopup()
      }
    })
  }
}
</script>

