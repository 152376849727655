<template>
  <control-date-picker
    :value="internal"
    @input="handleInput"
    @clear="update"
    v-bind="$attrs"
    :placeholder="filter.caption"
    :disabled-date="disabledDate"
    date-format="date"
    @close="update"
    range
  />
</template>

<script>

import { path } from 'ramda';

const minimumPath = path(['props', 'min']);
const maximumPath = path(['props', 'max']);
const DAY = 60 * 60 * 24 * 1000;
const toLower = date => {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
};
const toUpper = date => {
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);

  return date;
};

export default {
  name: 'filter-date-picker-widget',
  props: ['value', 'filter', 'available'],

  data() {
    return {
      changed: false,
      internal: [],
    };
  },

  computed: {
    minimum() {
      const value = minimumPath(this.available);

      return value && toLower(new Date(value)) || null;
    },
    maximum() {
      const value = maximumPath(this.available);

      return value && toUpper(new Date(value)) || null;
    },
    disabledDate() {
      return date => (
        this.minimum === null ||
        this.maximum === null ||
        this.minimum > date ||
        date > this.maximum
      );
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.internal = this.normalize(value);
      },
    },
  },

  methods: {
    update() {
      if (this.changed) {
        this.changed = false;
        this.input(this.internal);
      }
    },

    input(value) {
      this.$emit('input', this.clear(value));
    },

    clear(value) {
      let [minimum, maximum] = value;
      if (minimum === null || maximum === null) {
        return undefined;
      }

      maximum.setTime(maximum.getTime() + DAY - 1);
      [minimum, maximum] = this.normalize({ min: minimum, max: maximum });

      if (minimum === this.minimum && maximum === this.maximum) {
        return undefined;
      }

      return {
        min: toLower(minimum).toISOString(),
        max: toUpper(maximum).toISOString(),
      };
    },

    handleInput(value) {
      this.changed = true;
      this.internal = value;
    },

    normalize(value) {
      if (!value) {
        return [];
      }

      const { min: minimum, max: maximum } = value;
      const fit = v => (this.minimum ? Math.max(this.minimum, Math.min(this.maximum, v)) : v);

      return [
        new Date(fit(new Date(minimum))),
        new Date(fit(new Date(maximum))),
      ];
    },
  },
};

</script>
