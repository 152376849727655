import { route } from '@md/routing';
import { parcels } from '@md/delivery/api';
import { accessPermission } from '@md/users/permissions';
import {
  PARCELS_VIEW_LIST,
  PARCELS_VIEW,
  PARCELS_EDIT,
  PARCELS_CREATE,
} from '@md/delivery/accessTokens';
import { receiveRoute } from '@sections/cabinet/components/views/utils';

const List = () => import('./views/List');
// const Preview = () => import('./views/Preview');
const Edit = () => import('./views/Edit');
// const View = () => import('./views/View');
const Create = () => import('./views/Create');

const PARCELS_PREFIX = 'cabinet:delivery:parcels:';
const p = name => PARCELS_PREFIX + name;
export const LIST = p('list');
export const EDIT = p('edit');
// const VIEW = p('view');

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: accessPermission(PARCELS_VIEW_LIST, options),
    }),
    // route(':id/preview/', Preview, p('preview'), {
    //   meta: accessPermission(PARCELS_VIEW, options),
    // }),
    receiveRoute(parcels.retrieve, [
      route('edit/', Edit, EDIT, {
        meta: accessPermission(PARCELS_VIEW, options),
      }),
      { path: '', redirect: { name: EDIT } },
    ]),
    // receiveRoute(parcels.retrieve, [
    //   route('view/', View, VIEW, {
    //     meta: accessPermission(PARCELS_VIEW, options),
    //   }),
    //   { path: '', redirect: { name: VIEW } },
    // ]),
    route('create/', Create, p('create'), {
      meta: accessPermission(PARCELS_CREATE, options),
    }),

    { path: '', redirect: { name: LIST } },
  ];
}
