import { defaultDataTransformer } from '@aspectus/resource-commons';
import { createReceiver, createSender } from '@md/resources';
import { prefixAPI } from '@md/api';

const r = (tpl, f = createReceiver) => f(prefixAPI(tpl)).transform(defaultDataTransformer);

export const scanSheets = {
  list: r('/scan-sheets/list/{?filters,order_by,limit,offset}'),
  filters: {
    list: r('/scan-sheets/filters/list/'),
    available: r('/scan-sheets/filters/list/{?filters}'),
  },
  // addons: {
  //   statuses: r('/scan-sheets/addons/statuses/'),
  //   types: r('/scan-sheets/addons/types/'),
  // },
  // create: r('/scan-sheets/create/', createSender),
  // retrieve: r('/scan-sheets{/id}/retrieve/'),
  plural: {
    delete: r('/scan-sheets/plural/delete/', createSender),
  },
};

export const documents = {
  list: r('/documents/list/{?filters,order_by,limit,offset}'),
  filters: {
    list: r('/documents/filters/list/'),
    available: r('/documents/filters/list/{?filters}'),
  },
  addons: {
    statuses: r('/documents/addons/statuses/'),
  },
  plural: {
    create: r('/documents/plural/create/', createSender),
    insert: r('/documents/plural/insert/', createSender),
    delete: r('/documents/plural/delete/', createSender),
  },
};
