/**
field, dependencies: [one, two, three],

// Dependency management
watch(dependencies) ->

dependency.changed(value, old) -> true -> delay -> `push ${field} into updated set` ->

collectDependencyValues(field) ->

field.changedDependencies(dependentValues) ->

field.load(vm, dependencyStateHolder, dependentValues)

// Filtering
field.filter(vm, dependencyStateHolder, query)

// Paginating
field.paginate(vm, dependencyStateHolder, page)

// ---------
*/
<script>

import { partial, once, path } from 'ramda';
import { renderSlim } from '@aspectus/vue-utils';

const dependenciesReverser = dependencies => {
  const reversed = {};

  Object.keys(dependencies).forEach(source => dependencies[source].forEach(field => {
    reversed[field] = reversed[field] || [];
    reversed[field].push(source);
  }));

  return reversed;
}

const O = { type: Object, default: () => ({}) };

export default {
  name: 'vue-data-dependencies-resolver',
  props: {
    controllers: O,
    dependencies: O,
    data: O,
  },

  data() {
    return {
      reversed: dependenciesReverser(this.dependencies),
      options: {},
    };
  },

  created() {
    this.addWatchers();
  },

  watch: {
    data: { immediate: true, handler: 'bindControllers' },
  },

  methods: {
    bindControllers(value) {
      Object.keys(this.controllers).forEach(source => {
        const assoc = this.createAssoc(source);
        this.controllers[source].bind(value, this.createGetter(source), assoc);
        assoc({});
      });
    },

    addWatchers() {
      this.getDependentKeys(Object.keys(this.dependencies))
        .forEach(this.addWatcher);
    },

    addWatcher(field) {
      this.$watch('data.' + field, partial(this.handleChange, [field]));
      this.handleChange(field, undefined, null);
    },

    createGetter(source) {
      return () => this.options[source];
    },

    createAssoc(source) {
      return value => {
        this.options[source] = value;
        this.assocHandler = this.assocHandler || { reassoc: once(this.reassoc) };
        this.$nextTick(this.assocHandler.reassoc);
      };
    },

    reassoc() {
      delete this.assocHandler;

      this.$set(this, 'options', Object.assign({}, this.options));
    },

    getDependentKeys(fields, base = this.dependencies) {
      const reducer = (acc, x) => {
        base[x].forEach(field => acc[field] = true);

        return acc;
      };

      return Object.keys(fields.reduce(reducer, {}));
    },

    handleChange(field, value, old) {
      if (!this.controllers[field].changed(value, old)) {
        return;
      }
      this.pushToResolve(field);
    },

    pushToResolve(field) {
      this.resolver = this.resolver || { items: {} };

      if (this.resolver.items[field]) {
        return;
      }

      this.resolver.handler = this.resolver.handler || once(this.resolve);
      this.resolver.items[field] = true;

      this.$nextTick(this.resolver.handler);
    },

    collectValues(fields) {
      const reducer = (acc, x) => {
        acc[x] = path(x.split('.'), this.data);

        return acc;
      };

      return fields.reduce(reducer, {});
    },

    resolve() {
      // Collecting all fields, which dependencies was changed
      this.getDependentKeys(Object.keys(this.resolver.items), this.reversed)
        // Inform controller, that dependencies that he has was changed.
        // And do something with it.
        .forEach(field => {
          this.controllers[field].changedDependencies(
            this.collectValues(this.dependencies[field])
          );
        });

      delete this.resolver;
    },
  },

  render(h) {
    if (!this.$scopedSlots.default) {
      return null;
    }

    return renderSlim(this.$scopedSlots.default({
      resolve: this.resolve, options: this.options,
      dependencies: this.dependencies, reversed: this.reversed,
      controllers: this.controllers,
    }), h);
  }
};

</script>
