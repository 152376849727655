import { defaultDataTransformer } from '@aspectus/resource-commons';
import { createReceiver, createSender } from '@md/resources';
import { prefixAPI } from '@md/api';

const MODEL = '/staff';
const MANAGERS_MODEL = `${MODEL}/managers`;
const OPERATIONS_MANAGER_MODEL = `${MODEL}/operations-manager`;
const ASSISTANTS_MODEL = `${MODEL}/assistants`;
const STOCKMEN_MODEL = `${MODEL}/stockmen`;
const CASHBOX_TYPE_MODEL = `${MODEL}/cashbox-type`;
const TRANSFER_TYPE_MODEL = `${MODEL}/transfer-type`;
const ACCOUNTANT_TYPE_MODEL = `${MODEL}/accountant-type`;
const p = (url, model) => model + url;

const r = (tpl, f = createReceiver) => f(prefixAPI(tpl)).transform(defaultDataTransformer);

export const staff = {
  list: r(p('/list/{?filters,order_by,limit,offset}', MODEL)),
  filters: {
    list: r(p('/filters/list/', MODEL)),
    available: r(p('/filters/list/{?filters}', MODEL)),
  },
  addons: {
    types: r(p('/staff-addons/types/', MODEL)),
    locations: r(p('/staff-addons/locations/', MODEL)),
  },
  managers: {
    create: r(p('/create/', MANAGERS_MODEL), createSender),
    retrieve: r(p('{/id}/retrieve/', MANAGERS_MODEL)),
    update: r(p('{/id}/update/', MANAGERS_MODEL), createSender).config('method', 'PUT'),
  },
  operationsManagers: {
    create: r(p('/create/', OPERATIONS_MANAGER_MODEL), createSender),
    retrieve: r(p('{/id}/retrieve/', OPERATIONS_MANAGER_MODEL)),
    update: r(p('{/id}/update/', OPERATIONS_MANAGER_MODEL), createSender).config('method', 'PUT'),
  },
  assistants: {
    create: r(p('/create/', ASSISTANTS_MODEL), createSender),
    retrieve: r(p('{/id}/retrieve/', ASSISTANTS_MODEL)),
    update: r(p('{/id}/update/', ASSISTANTS_MODEL), createSender).config('method', 'PUT'),
  },
  stockmen: {
    create: r(p('/create/', STOCKMEN_MODEL), createSender),
    retrieve: r(p('{/id}/retrieve/', STOCKMEN_MODEL)),
    update: r(p('{/id}/update/', STOCKMEN_MODEL), createSender).config('method', 'PUT'),
  },
  cashboxType: {
    create: r(p('/create/', CASHBOX_TYPE_MODEL), createSender),
    retrieve: r(p('{/id}/retrieve/', CASHBOX_TYPE_MODEL)),
    update: r(p('{/id}/update/', CASHBOX_TYPE_MODEL), createSender).config('method', 'PUT'),
  },
  transferType: {
    create: r(p('/create/', TRANSFER_TYPE_MODEL), createSender),
    retrieve: r(p('{/id}/retrieve/', TRANSFER_TYPE_MODEL)),
    update: r(p('{/id}/update/', TRANSFER_TYPE_MODEL), createSender).config('method', 'PUT'),
  },
  accountantType: {
    create: r(p('/create/', ACCOUNTANT_TYPE_MODEL), createSender),
    retrieve: r(p('{/id}/retrieve/', ACCOUNTANT_TYPE_MODEL)),
    update: r(p('{/id}/update/', ACCOUNTANT_TYPE_MODEL), createSender).config('method', 'PUT'),
  },
};
