export const SCAN_SHEET_VIEW_LIST = 'NOVA_POSHTA_SCAN_SHEET_VIEW_LIST';
export const SCAN_SHEET_VIEW_ANY_LIST = 'NOVA_POSHTA_SCAN_SHEET_VIEW_ANY_LIST';
export const SCAN_SHEET_EDIT = 'NOVA_POSHTA_SCAN_SHEET_EDIT';
export const SCAN_SHEET_STATUS_EDIT = 'NOVA_POSHTA_SCAN_SHEET_STATUS_EDIT';
export const SCAN_SHEET_VIEW = 'NOVA_POSHTA_SCAN_SHEET_VIEW';
export const SCAN_SHEET_CREATE = 'NOVA_POSHTA_SCAN_SHEET_CREATE';

export const DOCUMENTS_VIEW_LIST = 'NOVA_POSHTA_DOCUMENTS_VIEW_LIST';
export const DOCUMENTS_VIEW_ANY_LIST = 'NOVA_POSHTA_DOCUMENTS_VIEW_ANY_LIST';
export const NOVA_POSHTA_DOCUMENTS_CREATE = 'NOVA_POSHTA_DOCUMENTS_CREATE';
