import { route } from '@md/routing';
// import { analytics } from '@md/analytics/api';
import { accessPermission } from '@md/users/permissions';
import {
  ANALYTICS_VIEW,
} from '@md/analytics/accessTokens';
// import { receiveRoute } from '@sections/cabinet/components/views/utils';

const View = () => import('./views/View');

const ANALYTICS = 'cabinet:analytics';

export function createRoutes(options) {
  return [
    route('', View, ANALYTICS, {
      meta: accessPermission(ANALYTICS_VIEW, options),
    }),

    { path: '', redirect: { name: ANALYTICS } },
  ];
}
