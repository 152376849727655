<template>
  <generator
    :filters="filters"
    :available="available"
    :value="value"
    v-slot="{ nodes }"
    v-on="$listeners"
  >
    <row :appearance="['spaced', 'nowrap-lg']">
      <slot name="prepend" cols="12 6-sm 3-md" />
        <cell
          cols="12 6-sm spread-sm spread-md auto-lg"
          v-for="filter in filters"
          v-if="nodes[filter.id]"
          :key="filter.id"
        >
          <render-slot :slot="nodes[filter.id]" :props="parameters" />
        </cell>
      <slot name="append" cols="12 6-sm 3-md" />
    </row>
  </generator>
</template>

<script>

import Generator from './Generator';

export default {
  components: {
    Generator,
  },
  props: [
    'disabled', 'value', 'filters', 'available',
  ],

  computed: {
    parameters() {
      return {
        disabled: this.disabled,
        values: this.value,
        size: 'md',
      };
    },
  },
};

</script>
