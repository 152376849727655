/* eslint-disable prefer-object-spread */

import { User } from './user';

export function createStore({ receiveResource, patchResource, UserClass = User }) {
  return {
    namespaced: true,
    state: {
      user: new UserClass({}, false),
      loading: false,
    },
    getters: {
      isAuthenticated: state => state.user.isAuthenticated(),
      isDetermined: state => state.user.determined,
    },

    mutations: {
      setUser(state, user) {
        state.user = user;
      },
      updateUser(state, data) {
        state.user = new UserClass(Object.assign({}, state.user.info, data));
      },
      setLoading(state, value) {
        state.loading = value;
      },
    },
    actions: {
      initialReceive(store) {
        if (store.state.user.determined) {
          return null;
        }
        return store.dispatch('lockedReceive');
      },
      lockedReceive(store) {
        if (store.state.loading) {
          return null;
        }

        return store.dispatch('receive');
      },
      receive(store) {
        store.commit('setLoading', true);

        return receiveResource.execute().then(user => {
          store.commit('setUser', new UserClass(user));
        }).catch(error => {
          store.commit('setUser', new UserClass({}));
          console.warn(error);
        }).finally(() => store.commit('setLoading', false));
      },

      patch(store, payload) {
        return patchResource.execute({}, payload).then(user => {
          store.commit('updateUser', user);
        });
      },
    },
  };
}
