import installer from '@aspectus/vue-route-installer';
import { accessMeta } from '@aspectus/vue-router-permissions';
import { route, prefixRoutes } from '@md/routing';
import { toBeAuthenticated, accessPermission, toHasOwnPermission, toHasPermission } from '@md/users/permissions';

import { createRoutes as createDeliveryRoutes } from './modules/delivery/router';
import { createRoutes as createClientsRoutes } from './modules/clients/router';
import { createRoutes as createAnalyticsRoutes } from './modules/analytics/router';
import { createRoutes as createLeadsRoutes } from './modules/leads/router';
import { createRoutes as createStaffRoutes } from './modules/staff/router';
import { createRoutes as createOrdersRoutes } from './modules/orders/router';
import { createRoutes as createLocalDeliveryRoutes } from './modules/loc-delivery/router';
// import { createRoutes as createSettingsRoutes } from './modules/settings/router';
import { createRoutes as createFinancesRoutes } from './modules/finances/router';
import { createRoutes as createNovaposhtaRoutes } from './modules/novaposhta/router';
import { createRoutes as createProfileRoutes } from './modules/profile/router';
import { createRoutes as createNotificationRoutes } from './modules/notifications/router';
import { createRoutes as createTransactionRoutes } from './modules/clients/views/TransactionsGroups/router';

const CabinetInstaller = () => import('./components');
const Cabinet = () => import('./views/Cabinet');

export function createRoutes(options) {
  const { Vue } = options;

  return [
    route('', installer(Cabinet, CabinetInstaller, Vue), null, {
      children: [
        prefixRoutes('profile/', createProfileRoutes(options)),

        prefixRoutes('analytics/', createAnalyticsRoutes(options)),
        prefixRoutes('delivery/', createDeliveryRoutes(options)),
        prefixRoutes('local-delivery/', createLocalDeliveryRoutes(options)),
        prefixRoutes('clients/', createClientsRoutes(options)),
        prefixRoutes('leads/', createLeadsRoutes(options)),
        prefixRoutes('staff/', createStaffRoutes(options)),
        prefixRoutes('orders/', createOrdersRoutes(options)),
        // prefixRoutes('settings/', createSettingsRoutes(options)),
        prefixRoutes('finances/', createFinancesRoutes(options)),
        prefixRoutes('novaposhta/', createNovaposhtaRoutes(options)),
        prefixRoutes('transaction/', createTransactionRoutes(options)),
        prefixRoutes('notifications/', createNotificationRoutes(options)),

        { path: '', redirect: 'profile/' },
      ],
      meta: accessMeta(toBeAuthenticated, { name: 'auth:login' }, options),
    }),
  ];
}
