import { formatTranslation } from '@md/strings';

export const normalizeDate = date => (
  date instanceof Date && !isNaN(date) && date || new Date()
);
export const parseDate = date => normalizeDate(new Date(date));
export const integer = value => (+value | 0);
export function transformDatetime(config, value) {
  return this.$d(parseDate(value), config);
}
export const install = Vue => {
  Vue.filter('normalizeDate', normalizeDate);
  Vue.filter('parseDate', parseDate);
  Vue.filter('integer', integer);
  Vue.filter('format', formatTranslation);
  Vue.filter('formatDate', date => date && date.toLocaleString('ru-Ru', {
    // hour: 'numeric',
    // minute: 'numeric',
  }));
  Vue.filter('localizeDate', value => value.replace(/\//g, '.'));
};

export default { install };
