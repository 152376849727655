export const statefulContext = context => ({
  attrs: context.$attrs,
  on: context.$listeners,
  scopedSlots: context.$scopedSlots,
});

export const isComponent = c => !!c.componentOptions || !!c.fnOptions;

export const scopedComponent = (h, Component, slot) => (
  slot ? slot : attrs => h(Component, { attrs })
);
