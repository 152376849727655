function getIntlNumberFormatWithUnit(unit = 'kilogram') {
  try {
    return new Intl.NumberFormat(undefined, {
      style: 'unit',
      unit,
    });
  } catch (e) {
    if (e.constructor !== RangeError) {
      throw e;
    }
    return null;
  }
}

const messages = {
  [window.language]: window.django.catalog,
};

const NUMERIC = 'numeric';
const LONG = 'long';
const DATE_CONFIG = {
  year: NUMERIC,
  month: NUMERIC,
  day: NUMERIC,
};
const SHORT_DATE_CONFIG = {
  ...DATE_CONFIG,
  year: '2-digit',
};
const TIME_CONFIG = {
  hour: NUMERIC,
  minute: NUMERIC,
};
const DATETIME_CONFIG = {
  ...DATE_CONFIG,
  ...TIME_CONFIG,
};

const dateTimeFormats = {
  [window.language]: {
    shortDate: SHORT_DATE_CONFIG,
    date: DATE_CONFIG,
    time: TIME_CONFIG,
    month: { year: NUMERIC, month: LONG },
    datetime: DATETIME_CONFIG,
  },
};

const SHORT = { notation: 'compact', compactDisplay: 'short' };
const SHORT_UNIT = {
  ...SHORT,
  unitDisplay: 'short',
  style: !!getIntlNumberFormatWithUnit ? 'unit' : 'decimal',
};
const u = unit => ({ ...SHORT_UNIT, unit: !!getIntlNumberFormatWithUnit ? unit : null });

const numberFormats = {
  [window.language]: {
    liter: u('liter'),
    short: SHORT,
    kilogram: u('kilogram'),
    degree: u('degree'),
  },
};

function translator() {
  return this.$t.apply(this, arguments);
}

export function createI18n({ Vue, I18n }) {
  Vue.prototype._ = translator;

  const i18n = new I18n({
    locale: window.language,
    silentTranslationWarn: process.env.NODE_ENV !== 'production',
    dateTimeFormats,
    numberFormats,
    messages,
  });

  return i18n;
}
