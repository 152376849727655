import { route } from '@md/routing';

const View = () => import('./View');

const PROFILE = 'cabinet:profile:';

export function createRoutes() {
  return [
    route('', View, PROFILE),

    { path: '', redirect: { name: PROFILE } },
  ];
}
