/* eslint-disable no-param-reassign, prefer-destructuring */

import { mergeContext } from '@aspectus/vue-utils';

const styles = getComputedStyle;
const toHeight = (element, height) => requestAnimationFrame(() => {
  element.style.height = height;
});
const repaint = element => styles(element).height;
const fit = (element, width, position, visibility, height) => {
  element.style.width = width;
  element.style.position = position;
  element.style.visibility = visibility;
  element.style.height = height;
};

function afterEnter(element) {
  element.style.height = null;
}

function enter(element) {
  // The element we want to animate starts out with a height of 0,
  // so if we use JavaScript to get its height, we’d get 0.
  // In order to get the height of the element it would have if it was
  // height: auto we have to set its height to auto.
  // But because this would affect other elements and because the element
  // would be visible(at least for a short period of time) we have to
  // position it absolute to prevent it having an effect on other
  // elements and we have to set its visibility to hidden so it is
  // invisible.
  // And because positioning an element absolute means its dimensions are
  // no longer defined by its parent, we have to get the width of the
  // element before we set its position to absolute and then set the
  // width explicitly so the element still has the same dimensions as
  // it would have if it was not positioned absolute.
  const width = styles(element).width;

  fit(element, width, 'absolute', 'hidden', 'auto');

  const height = styles(element).height;

  fit(element, null, null, null, 0);

  // Force repaint to make sure the
  // animation is triggered correctly.
  repaint(element);
  toHeight(element, height);
}

function leave(element) {
  const height = styles(element).height;
  element.style.height = height;

  // Force repaint to make sure the
  // animation is triggered correctly.
  repaint(element);
  toHeight(element, 0);
}

export default {
  name: 'transition-expand',

  functional: true,
  props: {
    name: {
      type: String,
      default: 'expand',
    },
  },

  render(h, context) {
    return h(
      'transition',
      mergeContext(context.data, {
        props: { name: context.props.name },
        on: { afterEnter, enter, leave },
      }),
      context.children
    );
  },
};
