<template lang="pug">

include /mixins.pug

row(v-if='total', appearance="spaced", align="baseline")
  cell(
    cols="3 2-md 1-xl",
    v-if="canChangeLimit",
  ): d-select(
      name="limit",
      @select="$event =>changeLimit($event)"
      :options="limitList"
      v-model="selectedLimit"
    )
  template(v-if='hasPrevious()')
    cell(cols="narrow"): control-button(styling="secondary", size="sm", @click.prevent='previous()') {{ _('Previous') }}
    cell(cols="narrow"): control-button(styling="secondary", size="sm", @click.prevent='select(range.min)') {{ range.min }}
  cell(cols="narrow", v-if='gaps.left') ...
  cell(cols="narrow", v-for='page in fills.left', :key="page"): control-button(styling="secondary", size="sm", @click.prevent='select(page)') {{ page }}
  cell(cols="narrow"): control-button(size="sm", disabled="") {{ getPageNumber() }}
  cell(cols="narrow", v-for='page in fills.right', :key="page"): control-button(styling="secondary", size="sm", @click.prevent='select(page)') {{ page }}
  cell(cols="narrow", v-if='gaps.right') ...
  template(v-if='hasNext()')
    cell(cols="narrow"): control-button(styling="secondary", size="sm", @click.prevent='select(range.max)') {{ range.max }}
    cell(cols="narrow"): control-button(styling="secondary", size="sm", @click.prevent='next()') {{ _('Next') }}

</template>

<script>

const PAGE_SHIFT = 1;

export default {
  name: 'catalog-pagination',
  props: {
    shift: { default: PAGE_SHIFT },
    total: {},
    limit: {},
    offset: {},
    extra: { default: 2 },
    canChangeLimit: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      limitList: [
        20,
        50,
        100,
      ],
      selectedLimit: this.limit,
      range: {
        min: 0,
        max: Infinity,
      },
      gaps: {
        left: false,
        right: false,
      },
      fills: {
        left: [],
        right: [],
      },
    };
  },

  created() {
    if (this.total) this.recalcRange();
  },

  watch: {
    total: { handler: 'recalcRange' },
    limit: { handler: 'recalcRange' },
    offset: { handler: 'recalcRange' },
  },

  methods: {
    hasNext() {
      return this.offset + this.limit < this.total;
    },

    hasPrevious() {
      return this.offset > 0;
    },

    getPageNumber(offset = this.offset, shift = this.shift) {
      return Math.floor(offset / this.limit) + shift;
    },

    getPageRange(distance = null, current = this.getPageNumber()) {
      const min = 1;
      const max = Math.max(min, Math.ceil(this.total / this.limit));

      if (distance === null || Infinity === distance) {
        return [min, max];
      }

      return [
        Math.max(min, current - distance),
        Math.min(max, current + distance),
      ];
    },

    recalcRange() {
      const [min, max] = this.getPageRange();
      const current = this.getPageNumber();
      const [start, end] = this.getPageRange(this.extra, current);
      // Some javascript magic going on here:
      // Filling up array with page numbers.
      const range = Array
        .apply(null, { length: end - start + 1 })
        .map((x, i) => i + start);
      const currentIndex = range.indexOf(current);

      if (currentIndex !== -1) {
        this.fills.left = range.slice(0, currentIndex).filter(x => x !== min);
        this.fills.right = range.slice(currentIndex + 1).filter(x => x !== max);
      } else {
        this.fills.left = this.fills.right = [];
      }

      this.gaps.left = (
        this.fills.left.length &&
        this.fills.left[0] !== min + 1
      );
      this.gaps.right = (
        this.fills.right.length &&
        this.fills.right[this.fills.right.length - 1] !== max - 1
      );
      this.range.min = min;
      this.range.max = max;
    },

    getParameters(number = null) {
      let page = 1;

      if (number !== null) {
        const [min, max] = this.getPageRange(Infinity, number);

        page = Math.min(max, Math.max(min, number));
      } else {
        page = this.getPageNumber();
      }

      return {
        offset: this.limit * (page - this.shift),
        limit: this.limit,
      };
    },
    changeLimit(number) {
      this.$emit('input', {
        offset: 0,
        limit: number,
      });
    },
    select(number) {
      this.$emit('input', this.getParameters(number));
    },

    next() {
      this.$emit('input', this.getParameters(this.getPageNumber() + this.shift));
    },

    previous() {
      this.$emit('input', this.getParameters(this.getPageNumber() - this.shift));
    },
  },
};

</script>
