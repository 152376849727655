import { route } from '@md/routing';
import { accessPermission } from '@md/users/permissions';
import {
  DOCUMENTS_VIEW_LIST,
} from '@md/novaposhta/accessTokens';

const List = () => import('./views/List');

const DOCUMENTS_PREFIX = 'cabinet:novaposhta:documents:';
const p = name => DOCUMENTS_PREFIX + name;
const LIST = p('list');

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: accessPermission(DOCUMENTS_VIEW_LIST, options),
    }),

    { path: '', redirect: { name: LIST } },
  ];
}
