import { route } from '@md/routing';
import { accessPermission } from '@md/users/permissions';
import {
  CASHBOX_VIEW_LIST,
  CASHBOX_CREATE,
} from '@md/finances/accessTokens';

const List = () => import('./views/List');
const Create = () => import('./views/Create');

const CASHBOX_PREFIX = 'cabinet:finances:cashbox:';
const p = name => CASHBOX_PREFIX + name;
const LIST = p('list');

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: accessPermission(CASHBOX_VIEW_LIST, options),
    }),
    route('create/', Create, p('create'), {
      meta: accessPermission(CASHBOX_CREATE, options),
    }),

    { path: '', redirect: { name: LIST } },
  ];
}
