<template functional lang="pug">

include /mixins.pug

+b.SVG.loading(
  v-bem:loading="{ size: props.size }"
  v-bind="data.attrs"
  v-on="data.on"
  viewBox="7 7 86 86"
  preserveAspectRatio="xMidYMid"
)
  +e.CIRCLE.background(
    cx="50"
    cy="50"
    r="40"
    fill="none"
  )
  +e.CIRCLE.foreground(
    cx="50"
    cy="50"
    r="40"
    fill="none"
  )
    animate(
      attributeName="stroke-dashoffset"
      :dur="props.duration"
      repeatCount="indefinite"
      from="0"
      to="502"
    )
    animate(
      attributeName="stroke-dasharray"
      :dur="props.duration"
      repeatCount="indefinite"
      values="150.6 100.4;1 250;150.6 100.4"
    )

</template>

<script>

export default {
  name: 'loading',
  props: {
    size: { default: 'md' },
    duration: { default: '2s' },
  },
};

</script>
