<template functional>
  <tag
    v-bind="data.attrs"
    v-on="data.on"
    :class="[data.class, data.staticClass, props.bclass()]"
  >
    <div :class="props.eclass('content')">
      <slot name="content" />
    </div>

    <div
      :class="props.eclass('backdrop')"
      @click.prevent="data.on && data.on['backdrop-click']($event)"
    >
      <slot name="backdop" />
    </div>

    <div :class="props.eclass('element')">
      <slot name="element" />
    </div>
  </tag>
</template>

<script>

import { Tag } from '@aspectus/vue-tag';
import {
  createStylingFunctionalMixin, mapSame, BLOCK_MODIFIERS, BLOCK_STATES,
} from '@aspectus/vue-bem-styling';

const StylingMixin = createStylingFunctionalMixin({
  modifiers: Object.assign({
    fullWidth: 'full-width',
  }, BLOCK_MODIFIERS),
  states: Object.assign(mapSame('opened', 'condenced', 'condencable'), BLOCK_STATES),
});

export default {
  name: 'drawer-layout',
  mixins: [StylingMixin],
  components: { Tag },
  props: {
    blockName: { type: String, default: 'drawer' },
    align: { type: String, default: 'right' },
    fullWidth: Boolean,
    condencable: Boolean,
    condenced: Boolean,
    opened: Boolean,
  },
};

</script>
