import Inputs from './inputs';
import Described from './described';
import Forms from './forms';
import Select from './Select';

function install(Vue) {
  Vue.use(Inputs);
  Vue.use(Described);
  Vue.use(Forms);

  Vue.component(Select.name, Select);
}

export default { install };
