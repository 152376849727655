import {
  mergeContext,
  withDefaultProps,
  internalPropsMagicTransform,
} from '@aspectus/vue-utils';
import InputElement from './InputElement';
import RootElement from './RootElement';
import Checker from './Checker';
import Point from './Point';
import File from './File';

const DATE_CONFIG = 'datetime';

function formatFabric(d, config = DATE_CONFIG) {
  return {
    stringify: date => (date ? d(date, config) : ''),
    parse: date => new Date(date),
  };
}
const wrp = (tag, blockName, root = InputElement) => withDefaultProps({ blockName, innerElement: () => tag })(root);
const eventHandlerFunctionFactory = data => e => (data.on && data.on.input && data.on.input(e.target.value));
function eventHandler(control) {
  return {
    functional: true,
    render: (h, context) => h(
      control,
      internalPropsMagicTransform(control, {

        ...context.data,
        on: {

          ...context.data.on,
          input: eventHandlerFunctionFactory(context.data),
        },
      }),
      context.children
    ),
  };
}
const DatePickerBase = {
  functional: true,
  props: ['formatFabric', 'dateFormat', 'format'],
  render(h, context) {
    const { format, dateFormat, formatFabric } = context.props;

    return h(InputElement, mergeContext(context.data, {
      attrs: {
        format: format || formatFabric(context.parent.$d.bind(context.parent), dateFormat),
      },
    }), context.children);
  },
};

export const CInput = wrp(eventHandler('input'), 'control-input');
export const CTextArea = wrp(eventHandler('textarea'), 'control-textarea');
export const CDatePicker = withDefaultProps({
  blockName: 'control-date-picker',
  innerElement: 'vue-date-picker',
  rangeSeparator: ' - ',
  lang: window.language,
  formatFabric: () => formatFabric,
  dateFormat: DATE_CONFIG,
})(DatePickerBase);
export const CCheckbox = withDefaultProps({ b: 'control-checkbox' })(Checker);
export const CRadio = withDefaultProps({ b: 'control-radio' })(Checker);
export const CButton = withDefaultProps({ blockName: 'control-button', tag: 'button' })(RootElement);
export const CPoint = Point;
export const CFile = File;

function install(Vue) {
  Vue.component('control-input', CInput);
  Vue.component('control-textarea', CTextArea);
  Vue.component('control-date-picker', CDatePicker);
  Vue.component('control-checkbox', CCheckbox);
  Vue.component('control-radio', CRadio);
  Vue.component('control-button', CButton);
  Vue.component(Point.name, Point);
  Vue.component(File.name, File);
}

export default { install };
