import { route } from '@md/routing';
import { accessPermission } from '@md/users/permissions';
import {
  NOTIFICATIONS_VIEW_LIST,
} from '@md/notifications/accessTokens';

const List = () => import('./views/List');

const CLIENTS_PREFIX = 'cabinet:notifications:';
const p = name => CLIENTS_PREFIX + name;
const LIST = p('list');

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: accessPermission(NOTIFICATIONS_VIEW_LIST, options),
    }),
    { path: '', redirect: { name: LIST } },
  ];
}
