export const FREIGHT_ORDERS_VIEW_LIST = 'DELIVERY_FREIGHT_ORDERS_VIEW_LIST';
export const FREIGHT_ORDERS_EDIT = 'DELIVERY_FREIGHT_ORDERS_EDIT';
export const FREIGHT_ORDERS_VIEW = 'DELIVERY_FREIGHT_ORDERS_VIEW';
export const FREIGHT_ORDERS_CREATE = 'DELIVERY_FREIGHT_ORDERS_CREATE';
export const FREIGHT_ORDERS_VIEW_CLIENT_DISPLAY = 'DELIVERY_FREIGHT_ORDERS_VIEW_CLIENT_DISPLAY';
export const FREIGHT_ORDERS_MANAGER_FILTER_VIEW = 'DELIVERY_FREIGHT_ORDERS_MANAGER_FILTER_VIEW';
export const FREIGHT_ORDERS_SHIPMENT_FILTER_VIEW = 'DELIVERY_FREIGHT_ORDERS_SHIPMENT_FILTER_VIEW';

export const SHIPMENTS_VIEW_ANY_LIST = 'DELIVERY_SHIPMENTS_VIEW_ANY_LIST';
export const SHIPMENTS_VIEW_LIST = 'DELIVERY_SHIPMENTS_VIEW_LIST';
export const SHIPMENTS_CREATE = 'DELIVERY_SHIPMENTS_CREATE';
export const SHIPMENTS_EDIT = 'DELIVERY_SHIPMENTS_EDIT';
export const SHIPMENTS_VIEW = 'DELIVERY_SHIPMENTS_VIEW';

export const PARCELS_VIEW_ANY_LIST = 'DELIVERY_PARCELS_VIEW_ANY_LIST';
export const PARCELS_VIEW_LIST = 'DELIVERY_PARCELS_VIEW_LIST';
export const PARCELS_CREATE = 'DELIVERY_PARCELS_CREATE';
export const PARCELS_VIEW = 'DELIVERY_PARCELS_VIEW';
export const PARCELS_EDIT = 'DELIVERY_PARCELS_EDIT';
export const PARCELS_STATUS_EDIT = 'DELIVERY_PARCELS_STATUS_EDIT';

export const PARCELS_FIT_VIEW = 'DELIVERY_PARCELS_FIT_VIEW';

export const DELIVERY_DISPATCH = 'DELIVERY_DISPATCH';
export const DELIVERY_RECEIVE = 'DELIVERY_RECEIVE';

export const DELIVERY_CLIENT_COST_VIEW = 'DELIVERY_CLIENT_COST_VIEW';
export const DELIVERY_FINANCES_FIELDS_VIEW = 'DELIVERY_FINANCES_FIELDS_VIEW';
export const DELIVERY_CLIENTS_FIELDS_VIEW = 'DELIVERY_CLIENTS_FIELDS_VIEW';
export const DELIVERY_NOTES_FIELDS_VIEW = 'DELIVERY_NOTES_FIELDS_VIEW';
export const DELIVERY_SHIPMENTS_FIELDS_VIEW = 'DELIVERY_SHIPMENTS_FIELDS_VIEW';
export const DELIVERY_PARCELS_MANAGER_FILTER_VIEW = 'DELIVERY_PARCELS_MANAGER_FILTER_VIEW';

export const DELIVERY_PARCEL_BARCODE_VIEW = 'DELIVERY_PARCEL_BARCODE_VIEW';
export const DELIVERY_PARCELS_SHIPPING_COST_VIEW = 'DELIVERY_PARCELS_SHIPPING_COST_VIEW';

export const DELIVERY_PARCELS_PLURAL_SHIPMENT_EDIT = 'DELIVERY_PARCELS_PLURAL_SHIPMENT_EDIT';

export const DELIVERY_UNIDENTIFIED_CREATE = 'DELIVERY_UNIDENTIFIED_CREATE';
export const DELIVERY_UNIDENTIFIED_EDIT = 'DELIVERY_UNIDENTIFIED_EDIT';
export const DELIVERY_UNIDENTIFIED_VIEW = 'DELIVERY_UNIDENTIFIED_VIEW';
export const DELIVERY_UNIDENTIFIED_VIEW_ANY_LIST = 'DELIVERY_UNIDENTIFIED_VIEW_ANY_LIST';
export const DELIVERY_UNIDENTIFIED_VIEW_LIST = 'DELIVERY_UNIDENTIFIED_VIEW_LIST';

export const DELIVERY_UNIDENTIFIED_SEARCH_VIEW_LIST = 'DELIVERY_UNIDENTIFIED_SEARCH_VIEW_LIST';
export const DELIVERY_UNIDENTIFIED_SEARCH_VIEW_ANY_LIST = 'DELIVERY_UNIDENTIFIED_SEARCH_VIEW_ANY_LIST';
export const DELIVERY_UNIDENTIFIED_SEARCH_VIEW = 'DELIVERY_UNIDENTIFIED_SEARCH_VIEW';
export const DELIVERY_UNIDENTIFIED_SEARCH_CREATE = 'DELIVERY_UNIDENTIFIED_SEARCH_CREATE';
export const DELIVERY_UNIDENTIFIED_SEARCH_EDIT = 'DELIVERY_UNIDENTIFIED_SEARCH_EDIT';
