import { route } from '@md/routing';
import { orders } from '@md/orders/api';
import { accessPermission } from '@md/users/permissions';
import {
  ORDERS_VIEW_LIST,
  ORDERS_EDIT,
  ORDERS_CREATE,
} from '@md/orders/accessTokens';
import { receiveRoute } from '@sections/cabinet/components/views/utils';

const List = () => import('./views/List');
const Edit = () => import('./views/Edit');
const Create = () => import('./views/Create');

const ORDERS_PREFIX = 'cabinet:orders:';
const p = name => ORDERS_PREFIX + name;
const LIST = p('list');
const EDIT = p('edit');

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: accessPermission(ORDERS_VIEW_LIST, options),
    }),
    route('create/', Create, p('create'), {
      meta: accessPermission(ORDERS_CREATE, options),
    }),
    receiveRoute(orders.retrieve, [
      // route('edit/', List, EDIT, {
      route('edit/', Edit, EDIT, {
        meta: accessPermission(ORDERS_EDIT, options),
      }),
      { path: '', redirect: { name: EDIT } },
    ]),

    { path: '', redirect: { name: LIST } },
  ];
}
