import { defaultDataTransformer } from '@aspectus/resource-commons';
import { createReceiver, createSender } from '@md/resources';
import { prefixAPI } from '@md/api';

const r = (tpl, f = createReceiver) => f(prefixAPI(tpl)).transform(defaultDataTransformer);

export const leads = {
  list: r('/leads/list/{?filters,order_by,limit,offset}'),
  addons: {
    statuses: r('/leads/addons/statuses/'),
    sources: r('/leads/addons/sources/'),
  },
  filters: {
    list: r('/leads/filters/list/'),
    available: r('/leads/filters/list/{?filters}'),
  },
  create: r('/leads/create/', createSender),
  retrieve: r('/leads{/id}/retrieve/'),
  update: r('/leads{/id}/update/', createSender).config('method', 'PUT'),
};
