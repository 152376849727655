import { route } from '@md/routing';
import { accessPermission } from '@md/users/permissions';
import {
  TRANSACTIONS_VIEW_LIST,
  TRANSACTIONS_CREATE,
} from '@md/finances/accessTokens';

const List = () => import('./views/List');
const Create = () => import('./views/Create');

const TRANSACTIONS_PREFIX = 'cabinet:finances:transactions:';
const p = name => TRANSACTIONS_PREFIX + name;
const LIST = p('list');

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: accessPermission(TRANSACTIONS_VIEW_LIST, options),
    }),
    route('create/', Create, p('create'), {
      meta: accessPermission(TRANSACTIONS_CREATE, options),
    }),

    { path: '', redirect: { name: LIST } },
  ];
}
