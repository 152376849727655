import { mergeContext } from '@aspectus/vue-utils';
import { tag, el } from '@aspectus/vue-dermis';
import { createBlockGenerator } from '@aspectus/bem';
import { truncateText } from '@md/strings';

const b = createBlockGenerator();

const [ReadMore, bl] = tag('read-more', {}, b);
const Visible = el('read-more-visible', bl, {}, 'visible');
const Toggler = el('read-more-toggler', bl, {}, 'toggler');

export default {
  name: 'read-more',
  props: {
    max: { default: Infinity, type: Number },
    deviation: { default: 15, type: Number },
    text: String,
    moreLabel: {},
    lessLabel: {},
    noLabel: Boolean,
  },
  inheritAttrs: false,
  data() {
    return { opened: false };
  },
  computed: {
    texts() {
      const { max, text, deviation } = this;
      const fits = max === Infinity || max + deviation > text.length;

      if (fits && !text.includes('\n') || this.opened) {
        return [text, fits];
      }

      return [truncateText(text, max - deviation, max, max + deviation)[0] + '...', false];
    },
  },
  methods: {
    toggle() {
      this.opened = !this.opened;
    },
  },
  render(h) {
    const { toggle, noLabel, opened } = this;
    const [text, fits] = this.texts;
    const on = { click: toggle };

    return h(
      ReadMore,
      mergeContext({
        attrs: this.$attrs, on: Object.assign({}, this.$listeners),
        scopedSlots: this.$scopedSlots,
      }, {
        on: noLabel ? on : {},
        class: { 'is-opened': opened || fits },
        attrs: {
          variant: noLabel ? [this.$attrs['variant'], 'no-label'] : undefined,
        },
      }),
      [
        h(Visible, {}, [text]),
        fits ? null : ' ',
        noLabel || fits ? null : h(
          Toggler, { on }, [opened ? this.lessLabel : this.moreLabel]
        ),
      ]
    );
  },
}
