import { createStore as createUsersStore } from '@md/users/store';

export function createStore(parameters) {
  return {
    modules: {
      users: createUsersStore(parameters),
    },
  };
}

export function createStoreObject({ Store, Vue }) {
  return new Store(createStore({ Store, Vue }));
}
