import { route } from '@md/routing';
import { freightOrders } from '@md/delivery/api';
import { accessPermission } from '@md/users/permissions';
import {
  FREIGHT_ORDERS_VIEW_LIST,
  FREIGHT_ORDERS_VIEW,
  FREIGHT_ORDERS_CREATE,
} from '@md/delivery/accessTokens';
import { receiveRoute } from '@sections/cabinet/components/views/utils';

const List = () => import('./views/List');
const Edit = () => import('./views/Edit/View');
const Create = () => import('./views/Create');

const FREIGHT_ORDERS_PREFIX = 'cabinet:delivery:freight-orders:';
const p = name => FREIGHT_ORDERS_PREFIX + name;
export const LIST = p('list');
export const EDIT = p('edit');

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: accessPermission(FREIGHT_ORDERS_VIEW_LIST, options),
    }),
    route('create/', Create, p('create'), {
      meta: accessPermission(FREIGHT_ORDERS_CREATE, options),
    }),
    receiveRoute(freightOrders.retrieve, [
      route('edit/', Edit, EDIT, {
        meta: accessPermission(FREIGHT_ORDERS_VIEW, options),
      }),
      { path: '', redirect: { name: EDIT } },
    ]),

    { path: '', redirect: { name: LIST } },
  ];
}
