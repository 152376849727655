<template lang="pug">

include /mixins.pug

+b.file-uploader
  vue-dropzone-uploader(
    :resource="uploadResource",
    @upload="uploadHandler",
    v-bind="$attrs",
    :config="config",
    :autoload="autoload"
    @drop='drop'
    @reject="rejectedError"
    ref="uploader"
  )
    template(#cover)
      +e.cover
        ds-caption(size="5") {{ _('Можно отпускать.') }}

    template(#default="uploader")
      slot(
        :open="open", :remove="remove", :uploader="uploader", :value="value"
      )
        +e.body(@click="open")
          +e.hint
            ds-caption(size="5") {{ _('Перенесите файлы сюда, или кликните для загрузки.') }}
          +e.files
            lightbox(
              v-slot="{ show }"
              :sources="value"
              @click.prevent.capture.stop=""
            )
              //- @click.stop=""
              //- :images="(value || []).map(el => el.file)"
              row(appearance="spaced")
                cell(
                  v-if="singleFile && file && file.id"
                  :cols="cols",
                  :key="file.id",
                  @click.prevent.stop=""
                )
                  file-preview(
                    :url="file.file", :title="file.title", :mime="file.mime"
                  )
                    template(#action)
                      ds-inliner(size="3xl")
                        control-button(styling="danger", @click.prevent.capture.stop="$emit('remove')", size="xs")
                          icon(name="close", size="lg")
                template(v-if="!singleFile")
                  cell(
                    :cols="cols",
                    v-for="(file, index) in value",
                    :key="file.id",
                    @click="requireLightbox ? show(index) : () => {}"
                  )
                    file-preview(
                      :url="file.file", :title="file.title", :mime="file.mime"
                    )
                      template(#action)
                        ds-inliner(size="3xl")
                          control-button(
                            styling="default",
                            @click="requireLightbox ? show(index) : () => {}"
                            size="xs"
                          )
                            icon(name="zoom_in", size="lg")
                        ds-inliner(size="3xl")
                          control-button(styling="danger", @click.prevent.capture.stop="remove(file)", size="xs")
                            icon(name="close", size="lg")
                cell(
                  v-for="(file, index) in uploader.accepted",
                  :cols="cols",
                  :key="file.id",
                  @click.stop=""
                )
                  div(
                    style="height: 10px; width: 100%;"
                  )
                    div(
                      :style="`height: 10px; background: #f7bc5d; width: ${file.progress.relative * 100}%;`"
                    )
                  file-preview(
                    :title="file.file.name",
                    :mime="file.file.type",
                    :progress="file.progress.relative"
                  )
                    template(#content)
                      d-input(
                        v-model="file && file.file && file.file.name",
                        type="text"
                      )
                    template(#action)
                      ds-inliner(size="3xl", v-if="file.status === 'ready'")
                        control-button(
                          styling="primary",
                          @click="uploader.upload(file, {title: file.file.name})",
                          size="xs"
                        )
                          icon(name="cloud_upload", size="lg")
                      ds-inliner(size="3xl", v-if="file.status === 'ready' || !singleFile")
                        control-button(styling="danger", @click="uploader.remove(file)", size="xs")
                          icon(name="close", size="lg")

</template>

<script>
import accepts from 'attr-accept';
import { filesUploadResource } from '@md/files/api';

export default {
  name: 'file-uploader',
  uploadResource: filesUploadResource,
  props: {
    singleFile: {
      type: Boolean,
      default: false,
    },
    requireLightbox: {
      type: Boolean,
      default: false,
    },
    autoload: {
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    file: {
      type: Object,
    },
    uploadResource: {
      default: () => filesUploadResource,
    },
    accept: String,
    cols: {
      type: String,
      default: '12 6-sm 4-md 3-lg',
    },
    handler: {
      type: Function,
    },
  },
  computed: {
    config() {
      return { acceptor: this.acceptor };
    },
  },
  methods: {
    uploadHandler({ result: { item: result }, descriptor }) {
      this.$refs.uploader.remove(descriptor);
      if (this.singleFile) {
        this.input(result);
      } else {
        this.input(this.value.concat([result]));
      }
    },
    input(data) {
      this.$emit('input', data);
    },
    open() {
      this.$refs.uploader.open();
    },
    drop(e) {
      const { accepted, rejected } = e;
      if (this.singleFile) {
        if (accepted.length) {
          this.$refs.uploader.accepted = [this.$refs.uploader.makeFile(accepted[0], 'ready')];
          if (this.$refs.uploader.autoload) {
            this.$refs.uploader.upload(
              this.$refs.uploader.accepted[0],
              { title: this.$refs.uploader.accepted[0].file.file.name }
            );
            // files.forEach(this.$refs.uploader.upload);
          }
        }
        if (rejected.length) {
          this.$emit('reject');
          const failed = rejected.map(file => this.$refs.uploader.makeFile(file, 'failure'));
          this.$refs.uploader.rejected = this.$refs.uploader.rejected.concat(failed);
        }
        // this.$refs.uploader.upload(
        //   this.handler(this.$refs.uploader.makeFile(e.accepted[0], 'ready'), this.file.title),
        //   { description: this.file.description }
        // );
      } else {
        const files = accepted.map(file => this.$refs.uploader.makeFile(file, 'ready'));
        const failed = rejected.map(file => this.$refs.uploader.makeFile(file, 'failure'));
        this.$refs.uploader.rejected = this.$refs.uploader.rejected.concat(failed);
        this.$refs.uploader.accepted = this.$refs.uploader.accepted.concat(files);
        failed.forEach(this.$refs.uploader.reject);
        if (this.$refs.uploader.autoload) {
          files.forEach(this.$refs.uploader.upload);
        }
      }
    },
    remove(file) {
      const { id } = file;

      const index = this.value.findIndex(x => x.id === id);
      if (index !== -1) {
        if (this.self) {
          this.$refs.uploader.accepted = [];
          const { title, description } = this.value[index];
          // this.input(
          this.value.splice(
            index,
            1,
            { title, description }
          );
          // );
        } else {
          this.input(
            this.value.slice(0, index).concat(this.value.slice(index + 1))
          );
        }
      }
    },
    acceptor(file) {
      if (file.size / 1024 / 1024 > 15) {
        return false;
      }
      return this.accept ? accepts(file, this.accept) : true;
    },
    rejectedError() {
      this.$toast.error(this._('File not allowed!'));
    },
  },
};
</script>
