import { defaultDataTransformer } from '@aspectus/resource-commons';
import { createResource, createReceiver, createSender } from '@md/resources';
import { prefixAPI } from '@md/api';

const r = (tpl, f = createReceiver) => f(prefixAPI(tpl)).transform(defaultDataTransformer);
const p = (url, model) => model + url;

export const transactions = {
  list: r('/transactions/list/{?filters,order_by,limit,offset}'),
  addons: {
    balanceTypes: r('/transactions/addons/balance-types/'),
    statuses: r('/transactions/addons/statuses/'),
    types: r('/transactions/addons/types/'),
  },
  filters: {
    list: r('/transactions/filters/list/'),
    available: r('/transactions/filters/list/{?filters}'),
  },
  groups: {
    list: r('/transactions/groups/client/{id}/list/{?filters,order_by,limit,offset}'),
    filters: {
      list: r('/transactions/groups/client/{id}/filters/list/'),
      available: r('/transactions/groups/client{/id}/filters/list/{?filters}'),
    },

    addons: {
      types: r('/transactions/groups/addons/types/'),
    },
  },
  create: r('/transactions/create/', createSender),
  retrieve: r('/transactions{/id}/retrieve/'),
  update: r('/transactions{/id}/update/', createSender).config('method', 'PATCH'),
};

export const salary = {
  list: r('/salary/list/{?filters,order_by,limit,offset}'),
  filters: {
    list: r('/salary/filters/list/'),
    available: r('/salary/filters/list/{?filters}'),
  },
  create: r('/salary/create/', createSender),
};
const PAYMENT_TRANSFERS_MODEL = '/payment-transfers';

export const paymentTransfers = {
  list: r(p('/list/{?filters,order_by,limit,offset}', PAYMENT_TRANSFERS_MODEL)),
  aggregate: r(p('/aggregate/{?filters,order_by,limit,offset}', PAYMENT_TRANSFERS_MODEL)),
  filters: {
    list: r(p('/filters/list/', PAYMENT_TRANSFERS_MODEL)),
    available: r(p('/filters/list/{?filters}', PAYMENT_TRANSFERS_MODEL)),
  },
  pluralUpdate: r(p('/plural/update/', PAYMENT_TRANSFERS_MODEL), createSender),
  create: r(p('/create/', PAYMENT_TRANSFERS_MODEL), createSender),
  export: createResource(prefixAPI(p('/export/excel/{?filters,order_by,limit,offset}', PAYMENT_TRANSFERS_MODEL))),
  retrieve: r(p('{/id}/retrieve/', PAYMENT_TRANSFERS_MODEL)),
  update: r(p('{/id}/update/', PAYMENT_TRANSFERS_MODEL), createSender).config('method', 'PATCH'),
  addons: {
    paymentTypes: r(p('/addons/payment-types/', PAYMENT_TRANSFERS_MODEL)),
    statuses: r(p('/addons/statuses/', PAYMENT_TRANSFERS_MODEL)),
  },
};

export const cashbox = {
  list: r('/cash/list/{?filters,order_by,limit,offset}'),
  aggregate: r('/cash/aggregate/{?filters,order_by,limit,offset}'),
  filters: {
    list: r('/cash/filters/list/'),
    available: r('/cash/filters/list/{?filters}'),
  },
  create: r('/cash/create/', createSender),
  addons: {
    currencies: r('/cash/addons/currencies/'),
    balanceTypes: r('/cash/addons/balance-types/'),
  },
};
