<template>
  <loading-wrapper :loading="loading">
    <row appearance="spaced">
      <cell :cols="filters.length > 1 ? 'auto' : '12 6-md 4-lg '">
        <filter-ui
          :disabled="loading"
          :value="prepared"
          :available="availableMap"
          :filters="filters"
          @input="handleInput"
          @filtrate="filtrate"
        />
      </cell>
      <cell cols="12 narrow-md">
        <row>
          <cell cols="auto">
            <control-button
              @click.prevent="filter"
              :disabled="loading"
              styling="primary"
              size="md"
            >
              <icon name="search" size="lg" />
            </control-button>
          </cell>
          <cell cols="auto" v-if="!empty">
            <control-button
              @click.prevent="clear"
              :disabled="loading"
              styling="danger"
              size="md"
            >
              <icon name="close" size="lg" />
            </control-button>
          </cell>
        </row>
      </cell>
    </row>
  </loading-wrapper>
</template>

<script>

import { isEmpty } from '@aspectus/vue-utils';
import isShallowEqual from '@wordpress/is-shallow-equal';
import FilterUi from './Ui';

function orderFilters(list, order) {
  const map = list.reduce((acc, x) => {
    acc[x.id] = x;
    return acc;
  }, {});

  return order.filter(x => !!map[x]).map(x => map[x]);
}

export default {
  inheritAttrs: false,
  props: [
    'loading', 'value', 'receive', 'all', 'available', 'receiveAvailable',
    'displayed', 'autoupdatable', 'permanent',
  ],

  components: {
    FilterUi,
  },

  data() {
    return {
      prepared: {},
    };
  },

  watch: {
    value: {
      immediate: true,
      handler({ filters = {} } = {}, { filters: oldFilters } = {}) {
        if (isShallowEqual(filters, oldFilters)) {
          return;
        }

        this.prepare(filters);
        this.receiveAvailable({ filters });
      },
    },
  },

  created() {
    this.receive(this.value);
    this.receiveAvailable(this.value);
  },

  computed: {
    empty() {
      return Object.values(this.prepared).every(isEmpty);
    },
    filters() {
      return (this.all && this.all.length && this.displayed)
        ? orderFilters(this.all, this.displayed)
        : [];
    },
    availableMap() {
      if (!this.available) {
        return {};
      }

      return this.available.reduce((acc, x) => {
        acc[x.id] = x;
        return acc;
      }, {});
    },
  },

  methods: {
    prepare(value) {
      this.prepared = value;
    },
    handleInputFilter(value) {
      this.handleInput(value);
      this.filter();
    },
    handleInput(value, key) {
      this.prepare(value);

      if (this.autoupdatable && this.autoupdatable.includes(key)) {
        this.receiveAvailable({ filters: this.prepared });
      }
    },
    filtrate(value, key) {
      this.prepared = value;
      this.filter();
    },
    filter() {
      this.$emit('input', { ...this.value, filters: this.prepared });
    },
    clear() {
      this.$emit('input', { ...this.value, filters: { }, ...this.permanent });
      this.prepare({ filters: {}, ...this.permanent });
    },
  },
};

</script>
