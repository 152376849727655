import Resolver from './Resolver';

function install(Vue, { resolverName = Resolver.name } = {}) {
  Vue.component(resolverName, Resolver);
}

export default { install };
export { Resolver };
export * from './controllers';
export * from './factories';
