<template>
  <control-input
    v-model.trim="model"
    @blur="close"
    @focus="open"
    @keyup.enter="$emit('filtrate', internal)"
    :placeholder="placeholder || filter.caption"
    v-bind="$attrs"
  />
</template>

<script>

import OpenableMixin from './OpenableMixin';

export default {
  name: 'filter-search-widget',
  props: ['value', 'filter', 'available', 'multiple', 'placeholder'],
  mixins: [OpenableMixin],
};

</script>
