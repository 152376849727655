import AuthPlugin from '@md/auth';

import { TOKENS } from './accessTokens';
import Plugin from './components';

function install(Vue) {
  Vue.use(AuthPlugin);
  Vue.use(Plugin);

  Vue.prototype.$accessTokens = TOKENS;
}

export default { install };
