import {
  userLoginResource, userUpdateResource,
  passwordResetResource, passwordConfirmResource, passwordChangeResource,
  passwordSet,
} from '@md/users/api';
import { filesUploadResource } from '@md/files/api';
import {
  freightOrders, parcels,
  shipments,
} from '@md/delivery/api';
import { clients } from '@md/clients/api';
import { orders } from '@md/orders/api';
import { leads } from '@md/leads/api';
import { analytics } from '@md/analytics/api';
import { staff } from '@md/staff/api';
import { transfers } from '@md/transfers/api';
import { transactions, salary, cashbox } from '@md/finances/api';
import { scanSheets, documents } from '@md/novaposhta/api';
import { notifications } from '@md/notifications/api';

const API = {
  files: {
    upload: filesUploadResource,
  },
  auth: {
    login: userLoginResource,
    user: {
      update: userUpdateResource,
    },
    password: {
      reset: passwordResetResource,
      confirm: passwordConfirmResource,
      set: passwordSet,
      change: passwordChangeResource,
    },
  },
  delivery: {
    freightOrders,
    parcels,
    shipments,
  },
  staff,
  transfers,
  clients,
  orders,
  leads,
  analytics,
  finances: {
    transactions,
    salary,
    cashbox,
  },
  novaposhta: {
    scanSheets,
    documents,
  },
  notifications,
};

function install(Vue) {
  Vue.prototype.$api = API;
}

export default { install };
