import { defaultDataTransformer } from '@aspectus/resource-commons';
import { createReceiver } from '@md/resources';
import { prefixAPI } from '@md/api';

const r = (tpl, f = createReceiver) => f(prefixAPI(tpl)).transform(defaultDataTransformer);

export const analytics = {
  leads: r('/analytics/leads/year/{?limit,offset}'),
  profit: r('/analytics/profit/year/{?limit,offset}'),
  parcels: r('/analytics/parcels/{?limit,offset,department}'),
  clients: r('/analytics/top-clients/{?limit,offset}'),
  managers: r('/analytics/top-managers/{?limit,offset}'),
  monthStatistic: r('/analytics/month-statistic/{?limit,offset}'),
};
