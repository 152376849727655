<template functional lang="pug">

include /mixins.pug

+b.box.--styling_layer.--variant_content(
  :class="[data.class, data.staticClass]",
  v-bind="data.attrs",
  v-on="data.listeners"
)
  +e.element(v-if="scopedSlots.title || scopedSlots.header || scopedSlots.subtitle || props.title || props.subtitle")
    slot(name="header")
      template(v-if="props.title || scopedSlots.title")
        slot(name="title"): ds-caption(:size="['4-md', '3-lg', '5']" variant="strong")
          | {{ props.title }}
      template(v-if="props.subtitle || scopedSlots.subtitle")
        slot(name="subtitle"): t-content: p {{ props.subtitle }}

  +e.element.--stacked_top(v-if="scopedSlots.default")
    slot

  slot(name="content")

  +e.element.--stacked_top(v-if="scopedSlots.footer")
    slot(name="footer")

</template>

<script>

export default {
  name: 'content-info-wrapper',
};

</script>
