/* eslint-disable max-classes-per-file */

export class UserBase {
  determined = false;

  info = null;

  isAuthenticated() {
    return this.determined && this.info && (this.info.id || this.info.pk) || false;
  }
}

export class User extends UserBase {
  constructor(info, determined = true) {
    super();

    this.determined = determined;
    this.info = info || this.info;
  }
}
