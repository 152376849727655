<template lang="pug">

include /mixins.pug

+b.TAG(
  v-bem:[blockName]="{ styling: styling, size: size, kind: kind, variant: variant }",
  v-state="{ disabled: disabled, readonly: readonly, invalid: invalid }",
  :tag="tag"
)
  +b.control-stack: row(space="none")
    cell(cols="narrow"): +e.CONTROL-BUTTON(
      appearance="label",
      tag="div",
      styling="input"
    ).element.--position_first {{ _('Lat:') }}
    cell(cols="auto"): +e.CONTROL-INPUT.element.--position_inner(
      :value="value && value.latitude", @input="update('latitude', $event)",
      v-on="handlers", type="number"
    )
    cell(cols="narrow"): +e.CONTROL-BUTTON(
      appearance="label",
      tag="div",
      styling="input"
    ).element.--position_inner {{ _('Lng:') }}
    cell(cols="auto"): +e.CONTROL-INPUT.element.--position_inner(
      :value="value && value.longitude", @input="update('longitude', $event)",
      v-on="handlers", type="number"
    )
    cell(cols="narrow"): +e.CONTROL-BUTTON(
      appearance="label",
      tag="div",
      styling="input"
    ).element.--position_last: icon(size="lg", name="place")

</template>

<script>

export default {
  name: 'control-point',
  props: {
    blockName: { type: String, default: 'control-point' },
    value: { default: () => {} },

    kind: {},
    variant: {},
    size: { default: 'md' },
    styling: { default: 'default' },
    disabled: Boolean,
    readonly: Boolean,
    invalid: Boolean,
    tag: { default: 'label' },
  },

  data() {
    this.handlers = {
      blur: e => this.$emit('blur', e),
      focus: e => this.$emit('focus', e),
    };

    return {};
  },

  methods: {
    update(key, value) {
      this.input({ ...this.value, [key]: value });
    },
    input(value) {
      this.$emit('input', value);
    },
  },
};

</script>
