<template lang="pug">

include /mixins.pug

validation-observer(v-slot="observer", ref="validator", slim)
  slot(
    :observer="observer",
    :submit="createSubmit(observer)",
    :reset="reset",
    :createSubmit="createSubmit",
    :send="send",
    :log="log",
    :options="options",
    :submitting="submitting",
    :disabled="disabledSubmit"
    :data="data",
    :result="result",
    :globalErrors="nonFieldErrors",
    :setCaptcha="setCaptcha"
  )

</template>

<script>

import { clone } from 'ramda';
import { GenericFormMixin } from '@aspectus/vue-forms';

const t = v => v;
const transformProp = { type: Function, default: t };

export default {
  mixins: [GenericFormMixin],
  props: {
    watchInitial: Boolean,
    initial: {
      default: () => ({}),
    },
    options: {
      default: () => ({}),
    },
    disabledSubmit: {
      type: Boolean,
      default: false,
    },
    sendResource: null,
    transformTo: transformProp,
    transformFrom: transformProp,
    getSendParameters: transformProp,
  },

  data() {
    this.captcha = null;

    return {
      result: null,
    };
  },

  watch: {
    initial: {
      immediate: true,
      handler(value, old) {
        if (!this.watchInitial && !(old === null || typeof old === 'undefined')) {
          return;
        }

        this.initializeData(value);
      },
    },
  },

  methods: {
    setCaptcha(captcha) {
      this.captcha = captcha;
      this.callCaptcha('execute');
    },
    callCaptcha(method) {
      const captcha = this.captcha ||
      this.$refs.invisibleRecaptcha || this.$parent.$refs.invisibleRecaptcha;
      captcha && captcha[method]();
    },
    initializeData(value = this.initial) {
      this.data = this.transformTo(clone(value));
    },
    createSubmit(observer) {
      return (
        event,
        addMore = false,
        valid = observer.valid,
        fields,
        additionals
      ) => observer.handleSubmit(() => {
        this.submit(valid, addMore, fields, additionals);
      });
    },
    send(data, addMore = false) {
      const transformed = this.transformFrom(data);

      return this.sendResource.execute(this.getSendParameters(transformed), transformed)
        .then(res => {
          addMore ? this.changeFinish(res) : this.finish(res);
        })
        .finally(r => {
          this.callCaptcha('reset');
          this.callCaptcha('execute');
          return r;
        });
    },
    log() {
      console.log.apply(console, arguments);
    },
    finish(result) {
      this.result = result;
      this.$emit('finish', { result, context: this });
      this.data.editing = false;
    },
    changeFinish(result) {
      this.result = result;
      this.$emit('finish-more', { result, context: this });
      this.data.editing = false;
    },
    reset() {
      this.resetValidator();
      this.initializeData();
      this.result = null;
    },
  },
};

</script>
