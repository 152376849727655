import {
  FREIGHT_ORDERS_VIEW_LIST, SHIPMENTS_VIEW_LIST,
  PARCELS_VIEW_LIST, DELIVERY_DISPATCH, DELIVERY_RECEIVE,
  DELIVERY_UNIDENTIFIED_VIEW_ANY_LIST, DELIVERY_UNIDENTIFIED_SEARCH_VIEW_ANY_LIST,
} from '@md/delivery/accessTokens';
import { prefixRoutes } from '@md/routing';
import { accessPermission } from '@md/users/permissions';

import { createRoutes as createFreightOrdersRoutes } from './modules/freight-orders/router';
import { createRoutes as createShipmentsRoutes } from './modules/shipments/router';
import { createRoutes as createParcelsRoutes } from './modules/parcels/router';
import { createRoutes as createUnidentifiedRoutes } from './modules/unidentified/router';
import { createRoutes as createUnidentifiedSearchRoutes } from './modules/unidentified-search/router';
import { createRoutes as createVerificationRoutes } from './modules/verification/router';
import { createRoutes as createScanningRoutes } from './modules/scanning/router';

export function createRoutes(options) {
  return [
    prefixRoutes('freight-orders/', createFreightOrdersRoutes(options), {
      meta: accessPermission(FREIGHT_ORDERS_VIEW_LIST, options),
    }),

    prefixRoutes('shipments/', createShipmentsRoutes(options), {
      meta: accessPermission(SHIPMENTS_VIEW_LIST, options),
    }),

    prefixRoutes('parcels/', createParcelsRoutes(options), {
      meta: accessPermission(PARCELS_VIEW_LIST, options),
    }),
    prefixRoutes('unidentified/', createUnidentifiedRoutes(options), {
      meta: accessPermission(DELIVERY_UNIDENTIFIED_VIEW_ANY_LIST, options),
    }),
    prefixRoutes('unidentified-search/', createUnidentifiedSearchRoutes(options), {
      meta: accessPermission(DELIVERY_UNIDENTIFIED_SEARCH_VIEW_ANY_LIST, options),
    }),
    prefixRoutes('verification/', createVerificationRoutes(options), {
      meta: accessPermission(DELIVERY_DISPATCH, options),
    }),
    prefixRoutes('scanning/', createScanningRoutes(options), {
      meta: accessPermission(DELIVERY_RECEIVE, options),
    }),
    { path: '', redirect: 'freight-orders/' },
  ];
}
