import { route } from '@md/routing';
import { clients } from '@md/clients/api';
import { accessPermission } from '@md/users/permissions';
import {
  CLIENT_VIEW_EXTENDED_LIST,
  CLIENT_EDIT,
  CLIENT_CREATE,
} from '@md/clients/accessTokens';
import { receiveRoute } from '@sections/cabinet/components/views/utils';

const List = () => import('./views/List');
const Edit = () => import('./views/Edit');
const Create = () => import('./views/Create');

const CLIENTS_PREFIX = 'cabinet:clients:';
const p = name => CLIENTS_PREFIX + name;
const LIST = p('list');
const EDIT = p('edit');

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: accessPermission(CLIENT_VIEW_EXTENDED_LIST, options),
    }),
    route('create/', Create, p('create'), {
      meta: accessPermission(CLIENT_CREATE, options),
    }),
    receiveRoute(clients.retrieve, [
      route('edit/', Edit, EDIT, {
        meta: accessPermission(CLIENT_EDIT, options),
      }),
      { path: '', redirect: { name: EDIT } },
    ]),

    { path: '', redirect: { name: LIST } },
  ];
}
