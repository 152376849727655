import { createReceiver, createSender } from '@md/resources';
import { prefixAPI } from '@md/api';

export const USER_LOGIN = prefixAPI('/auth/login/');
export const USER_INFO = prefixAPI('/auth/user/');
export const USER_PASSWORD_RESET = prefixAPI('/auth/password/reset/');
export const USER_PASSWORD_CONFIRM = prefixAPI('/auth/password/reset/confirm/');
export const USER_PASSWORD_SET = prefixAPI('/auth/password/confirm/');
export const USER_PASSWORD_CHANGE = prefixAPI('/auth/password/change/');

export const userLoginResource = createSender(USER_LOGIN);
export const userInfoResource = createReceiver(USER_INFO);
export const userUpdateResource = createSender(USER_INFO).config('method', 'PATCH');
export const passwordResetResource = createSender(USER_PASSWORD_RESET);
export const passwordConfirmResource = createSender(USER_PASSWORD_CONFIRM);
export const passwordSet = createSender(USER_PASSWORD_SET);
export const passwordChangeResource = createSender(USER_PASSWORD_CHANGE);
