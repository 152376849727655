import { defaultDataTransformer } from '@aspectus/resource-commons';
import { createReceiver, createSender } from '@md/resources';
import { prefixAPI } from '@md/api';

const r = (tpl, f = createReceiver) => f(prefixAPI(tpl)).transform(defaultDataTransformer);

export const transfers = {
  addons: {
    // types: r('/transfers/'),
    types: r('/transfers/addons/types/'),
  },
  delete: r('/transfers{/id}/delete/', createSender).config('method', 'DELETE'),
  list: r('/transfers/list/{?filters,order_by,limit,offset}'),
  filters: {
    list: r('/transfers/filters/list/'),
    available: r('/transfers/filters/list/{?filters}'),
  },
  npDoor: {
    create: r('/transfers/np-door/create/', createSender),
    retrieve: r('/transfers/np-door{/id}/retrieve/'),
    update: r('/transfers/np-door{/id}/update/', createSender).config('method', 'PUT'),
  },
  npWarehouse: {
    create: r('/transfers/np-warehouse/create/', createSender),
    retrieve: r('/transfers/np-warehouse{/id}/retrieve/'),
    update: r('/transfers/np-warehouse{/id}/update/', createSender).config('method', 'PUT'),
  },
  tcgStock: {
    create: r('/transfers/tcg-stock/create/', createSender),
    retrieve: r('/transfers/tcg-stock{/id}/retrieve/'),
    update: r('/transfers/tcg-stock{/id}/update/', createSender).config('method', 'PUT'),
  },
};

export const novaposhta = {
  cities: r('/novaposhta/address/cities/{?s}'),
  warehouses: r('/novaposhta/address/warehouses/{?s,city}'),
  streets: r('/novaposhta/address/streets/{?s,city}'),

};

export const localDeliveryApi = {
  addons: {
    statuses: r('/local-delivery/addons/statuses/list/'),
    types: r('/local-delivery/addons/types/list/'),
  },
  create: r('/local-delivery/create/', createSender),
  list: r('/local-delivery/list/{?filters,order_by,limit,offset}'),
  filters: {
    list: r('/local-delivery/filters/list/'),
    available: r('/local-delivery/filters/list/{?filters}'),
  },
  track: r('/local-delivery/track/', createSender),
  retrieve: r('/local-delivery{/id}/retrieve/'),
  update: r('/local-delivery{/id}/update/', createSender).config('method', 'PATCH'),
};
