import { GeneratorMixin } from '@aspectus/vue-filters-generator';

import SearchWidget from './widgets/SearchWidget';
import SelectWidget from './widgets/SelectWidget';
import SliderWidget from './widgets/SliderWidget';
import MultiselectWidget from './widgets/MultiselectWidget';
import TypesMultiselectWidget from './widgets/TypesMultiselectWidget';
import DatePickerWidget from './widgets/DatePickerWidget';
// import CombinedInputWidget from './widgets/CombinedInputWidget';
// import AutocompleteMultiselectWidget from './widgets/AutocompleteMultiselectWidget';

const WIDGETS_MAP = {
  search: SearchWidget,
  select: SelectWidget,
  'decimal-range': SliderWidget,
  'date-range': DatePickerWidget,
  multiselect: MultiselectWidget,
  icons: TypesMultiselectWidget,
  // 'combined-input': CombinedInputWidget,
  // 'autocomplete-multiselect': AutocompleteMultiselectWidget,
};

export default {
  extends: GeneratorMixin,
  widgetsMap: WIDGETS_MAP,
};
