import { mergeContext, renderSlim } from '@aspectus/vue-utils';
import { checkerMap } from './utils';

export default {
  widgetsMap: {},
  props: {
    value: {},
    filters: Array,
    keyGetter: {
      type: Function,
      default: e => e.id,
    },
    typeGetter: {
      type: Function,
      default: e => e.type,
    },
    available: Object,
    omit: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    omitMap() {
      return checkerMap(this.omit);
    },
    availableFilters() {
      return this.filters
        .filter(filter => this.typeGetter(filter) in this.$options.widgetsMap)
        .filter(filter => !this.omitMap[this.keyGetter(filter)]);
    },
    nodes() {
      return this.availableFilters.reduce((acc, filter) => {
        acc[this.keyGetter(filter)] = this.renderFilter(this.$createElement, filter);

        return acc;
      }, {});
    },
  },

  methods: {
    renderFilter(h, filter) {
      return (props, context = {}) => [h(
        this.$options.widgetsMap[this.typeGetter(filter)],
        mergeContext({
          props: {
            filter,
            available: this.available && this.available[this.keyGetter(filter)],
            value: this.value[this.keyGetter(filter)],
            keyGetter: this.keyGetter,
            ...props,
          },
          on: {
            input: value => this.update(this.keyGetter(filter), value),
            filtrate: value => this.filtrate(this.keyGetter(filter), value),
          },
          
        }, context.data),
        context.children
      )];
    },
    update(key, value) {
      this.$emit('input', { ...this.value, [key]: value }, key);
    },
    filtrate(key, value) {
      this.$emit('filtrate', { ...this.value, [key]: value }, key);
    },
  },

  render(h) {
    return renderSlim(this.$scopedSlots.default({
      nodes: this.nodes,
    }), h, 'tag');
  },
};
