<template lang="pug">
  div
    new-viewer(
      :options="options"
      :images="images"
      @inited="inited"
      class="viewer"
      ref="viewer"
      rebuild
    )
      template(v-slot:default="scope" )
        row(appearance="spaced"): cell(
          cols="6 4-sm 3-md 2-lg"
          v-for="image in scope.images"
          :key="image.id"
        )
          ds-aspect-ratio(:appearance="['cuted']", ratio="16x9")
            img.ds-aspect-ratio__body(:src="image.file" :key="image.id")

</template>
<script>
import 'viewerjs/dist/viewer.css';
import NewViewer from 'v-viewer/src/component';

export default {
  name: 'viewer-image',
  components: { NewViewer },
  props: {
    images: Array,
    classes: {
      type: String,
      default: 'galary-image',
    },
  },
  data() {
    return {
      options: {},
    };
  },
  methods: {
    inited(viewer) {
      this.$viewer = viewer;
    },
    show() {
      this.$viewer.show();
    },
  },
};
</script>
