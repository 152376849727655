import { resultValue, toArray, getKey, isValue } from '@aspectus/selection-controller';
import {
  updateValue, defaultKeyGetter, DIRECTIONS_PATH, defaultDirectionGetter,
} from '@aspectus/ordering-controller';
import { renderSlim } from '@aspectus/vue-utils';
import { Tag } from '@aspectus/vue-tag';

export default {
  name: 'vue-ordering-controller',

  props: {
    multiple: { type: Boolean, default: false },
    value: {},
    keyGetter: { type: Function, default: defaultKeyGetter },
    directionGetter: { type: Function, default: defaultDirectionGetter },
    path: { type: Object, default: () => DIRECTIONS_PATH },
    updator: { type: Function, default: updateValue },
  },

  computed: {
    valueMap() {
      return toArray(this.value).filter(isValue).reduce((acc, x) => {
        acc[getKey(x, this.keyGetter)] = x;

        return acc;
      }, {});
    },
  },

  methods: {
    change(change) {
      const value = this.updator(
        change, this.value, this.multiple, this.path,
        this.keyGetter, this.defaultDirectionGetter
      );

      this.$emit('input', resultValue(value, this.multiple));
    },
  },

  render(h) {
    const { change, multiple, keyGetter, directionGetter, path } = this;
    const { value, valueMap } = this;

    return renderSlim(this.$scopedSlots.default({
      value, valueMap, multiple, change, keyGetter, directionGetter, path,
    }), h, Tag);
  },
};
