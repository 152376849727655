import { defaultValidatorErrorsParser } from './parsers';

export default {
  data() {
    return {
      nonFieldErrors: [],
    };
  },

  methods: {
    resetValidator() {
      this.nonFieldErrors = [];

      if (this.$refs.validator) {
        this.$refs.validator.reset();
      }
    },
    catchUnexpected(e) {
      this.nonFieldErrors = [this._('Неизвестная ошибка при отправке формы.')];
    },

    updateValidator(errors = null) {
      if (errors === null) {
        this.resetValidator();
        return;
      }

      const parsed = defaultValidatorErrorsParser(errors);
      let applied = false;

      Object.keys(parsed).forEach(key => {
        if (key === 'nonFieldErrors') {
          this.nonFieldErrors = parsed[key];
          applied = true;
        }

        if (!this.$refs.validator.refs[key]) {
          return;
        }

        applied = true;

        this.$refs.validator.refs[key].applyResult({
          errors: parsed[key],
          valid: false,
          failedRules: {},
        });
      });

      if (!applied) {
        this.catchUnexpected();
      }
    },
  },
};
