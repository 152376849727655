export default {
  data() {
    return {
      data: {},
      submitting: false,
    };
  },
  methods: {
    collectFields(fields = []) {
      if (!fields || !fields.length) {
        return this.data;
      }

      return fields.reduce((acc, x) => {
        acc[x] = this.data[x];

        return acc;
      }, {});
    },
    catchUnexpected(e) {
      throw e;
    },
    catchFormErrors(promise) {
      if (!this.updateValidator) {
        return promise;
      }

      return promise
        .then(response => {
          this.updateValidator();

          return response;
        })
        .catch(e => {
          if (!e.status || e.status < 400 || e.status >= 500) {
            throw e;
          }

          const promise = e.json();
          promise.catch(this.catchUnexpected);

          return promise.then(body => {
            const errors = {};

            body.errors.forEach(error => {
              if (error.domain === 'request' && error.state) {
                Object.assign(errors, error.state);
              }
            });

            this.updateValidator(errors);

            return e;
          });
        });
    },
    submit(valid, addMore = false, fields = [], additional = {}) {
      console.log('submit', valid, addMore, fields, additional);
      if (!valid) {
        if (this.$refs.validator) {
          this.$refs.validator.validate();
        }

        return Promise.reject();
      }

      this.submitting = true;

      return this.catchFormErrors(this.send(
        Object.assign(this.collectFields(fields), additional), addMore
      ))
        .catch(e => {
          this.$toast.error(this._('Submission failed!'));

          throw e;
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
