import General from './general';
import Info from './info';
import Controls from './controls';
import Forms from './forms';
import Catalog from './catalog';
import FiltersView, { LoadableFiltersView } from './filter';

function install(Vue) {
  Vue.use(General);
  Vue.use(Info);
  Vue.use(Controls);
  Vue.use(Forms);
  Vue.use(Catalog);

  Vue.component('ui-filters-view', FiltersView);
  Vue.component('ui-loadable-filters-view', LoadableFiltersView);
}

export default { install };
