const defaultKeyGetter = e => e;

export class ValueResolver {
  constructor(dependencies) {
    this.dependencies = dependencies;
  }

  prepare(value) {
    return Object.keys(this.dependencies).reduce((acc, x) => {
      const dep = this.dependencies[x];
      acc[dep.field] = (dep.transform)(value);

      return acc;
    });
  }
}

export class Controller {
  constructor() {}

  bind(vm, getter, assoc) {
    this.vm = vm;
    this.getter = getter;
    this.assoc = assoc;
  }

  set(value) {
    this.assoc(Object.assign({}, this.getter(), value));
  }

  changed(value, old) {
    return value == old;
  }

  changedDependencies() {}
}

export class KeyGettableController extends Controller {
  constructor(options = {}) {
    super(options);
    const { keyGetter = defaultKeyGetter } = options;

    this.keyGetter = keyGetter;
  }

  changed(value, old) {
    return this.keyGetter(value) == this.keyGetter(old);
  }
}

export class ResourceController extends KeyGettableController {
  constructor(options = {}) {
    super(options);
    const { resource, valueResolver } = options;

    this.resource = resource;
    this.valueResolver = valueResolver;
    this.handleResult = this.handleResult.bind(this);
  }

  changedDependencies(value) {
    const prepared = this.valueResolver ? this.valueResolver.prepare(value) : value;
    prepared.limit = 9999999
    this.set({ loading: true });
    this.resource.execute(prepared)
      .then(this.handleResult)
      .finally(() => this.set({ loading: false }));
  }

  handleResult(result) {
    this.getter(this.vm).result = result;

    return result;
  }
}
