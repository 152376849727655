import { route } from '@md/routing';

const Verify = () => import('./views/Verify');

const VERIFY = 'cabinet:delivery:verification';

export function createRoutes() {
  return [
    route('', Verify, VERIFY),
  ];
}
