function get(key, d = null) {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (e) { }

  return d;
}

function set(key, value = null) {
  return localStorage.setItem(key, JSON.stringify(value));
}

export default { get, set };
