export const CLIENT_VIEW_ANY_LIST = 'PROFILE_CLIENT_VIEW_ANY_LIST';
export const CLIENT_CREATE = 'PROFILE_CLIENT_CREATE';
export const CLIENT_EDIT = 'PROFILE_CLIENT_EDIT';
export const CLIENT_VIEW = 'PROFILE_CLIENT_VIEW';
export const CLIENT_VIEW_LIST = 'PROFILE_CLIENT_VIEW_LIST';
export const CLIENT_VIEW_EXTENDED_LIST = 'PROFILE_CLIENT_VIEW_EXTENDED_LIST';

export const PROFILE_COMPLAINT_CREATE = 'PROFILE_COMPLAINT_CREATE';
export const PROFILE_CLIENT_DEACTIVATE = 'PROFILE_CLIENT_DEACTIVATE';
export const PROFILE_COMMENTS_VIEW_LIST = 'PROFILE_COMMENTS_VIEW_LIST';
export const PROFILE_COMMENTS_CREATE = 'PROFILE_COMMENTS_CREATE';
export const PROFILE_CLIENT_COMMENT_FIELD_VIEW = 'PROFILE_CLIENT_COMMENT_FIELD_VIEW';
export const PROFILE_CLIENT_CONTACTS_EDIT = 'PROFILE_CLIENT_CONTACTS_EDIT';

export const PROFILE_CLIENT_MANAGER_EDIT = 'PROFILE_CLIENT_MANAGER_EDIT';
export const PROFILE_CLIENT_RATE_EDIT = 'PROFILE_CLIENT_RATE_EDIT';
