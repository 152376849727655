import { defaultDataTransformer } from '@aspectus/resource-commons';
import { createReceiver, createSender } from '@md/resources';
import { prefixAPI } from '@md/api';

const r = (tpl, f = createReceiver) => f(prefixAPI(tpl)).transform(defaultDataTransformer);

export const notifications = {
  check: r('/notifications/check/'),
  list: r('/notifications/list/{?filters,order_by,limit,offset}'),
  // addons: {
  //   statuses: r('/notifications/addons/statuses/'),
  // },
  filters: {
    list: r('/notifications/filters/list/'),
    available: r('/notifications/filters/list/{?filters}'),
  },
  create: r('/notifications/create/', createSender),
  retrieve: r('/notifications{/id}/retrieve/'),
  update: r('/notifications{/id}/update/', createSender).config('method', 'PATCH'),
};
