export default {
  sendResource: null,

  methods: {
    finish() {},

    send(data) {
      return this.$options.sendResource.execute({}, data)
        .then(this.finish);
    },
  },
};
