import { mergeContext } from '@aspectus/vue-utils';
import { TabsTag, TabsLabelsTag } from './tags';
import { statefulContext, isComponent, scopedComponent } from './utils';

export default {
  name: 'vue-tabs',
  props: {
    initial: { default: 0, type: Number },
    remainDom: Boolean,
    labelComponent: {},
    labelsComponent: {},
  },
  data() {
    return {
      selected: this.initial,
    };
  },
  methods: {
    switchTab(index, isDisabled) {
      if (!isDisabled) {
        this.selected = index;

        this.$emit('select', index);
      }
    },
    createLabels(nodes) {
      return nodes.map((node, index) => {
        const {
          label, labelSlot, disabled, props,
        } = node.componentOptions.propsData;

        return {
          disabled, props, selected: this.selected === index,
          label: labelSlot && this.$slots[labelSlot] || label,
          switch: e => this.switchTab(index, disabled),
        };
      });
    },
    renderLabels(h, labels) {
      const { labelComponent, labelsComponent } = this;
      if (labelsComponent) {
        return h(labelsComponent, {
          attrs: { labels },
          scopedSlots: { default: this.$scopedSlots.labels },
        });
      }

      if (this.$scopedSlots.labels) {
        return this.$scopedSlots.labels({ labels });
      }

      return labels.map(scopedComponent(
        h, labelComponent, this.$scopedSlots.label
      ));
    },
    renderNodes(h, nodes) {
      const { selected } = this;

      return !this.remainDom ? nodes[this.selected] : nodes.map((node, i) => h(
        'div',
        { directives: [{ name: 'show', value: selected === i }] },
        [node]
      ));
    }
  },
  render(h) {
    const nodes = this.$slots.default.filter(isComponent);
    const labels = this.renderLabels(h, this.createLabels(nodes));

    return h(
      TabsTag,
      mergeContext(statefulContext(this), { attrs: { role: 'tabs' } }),
      [
        h(TabsLabelsTag, { attrs: { role: 'tablist' } }, [
          this.$slots.prepend, labels, this.$slots.append,
        ]),
        this.renderNodes(h, nodes),
      ]
    );
  },
};
