import { route } from '@md/routing';

const Login = () => import('./views/Login');
const PasswordReset = () => import('./views/PasswordReset');
const PasswordConfirm = () => import('./views/PasswordConfirm');
const PasswordSet = () => import('./views/PasswordSet');

export function createRoutes({ Vue, Router }) {
  return [
    route('login/', Login, 'auth:login'),
    route('password/reset/', PasswordReset, 'auth:password-reset'),
    route('password/confirm/', PasswordConfirm, 'auth:password-confirm'),
    route('password/set/', PasswordSet, 'auth:password-set'),

    { path: '', redirect: { name: 'auth:login' } },
  ];
}
