import { createRouter } from '@/router';
import { createI18n } from '@/i18n';
import { createStoreObject } from '@/store';
import App from '@/App.vue';

export function createApp({ Vue, Router, Store, I18n }) {
  const store = createStoreObject({ Vue, Store });
  const i18n = createI18n({ Vue, I18n });
  const router = createRouter({ Vue, Router, store });
  const app = new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
  });

  return { app, router, store, i18n };
}

export {
  createRouter,
};
