<template lang="pug" functional>
  div.language-selector
    row(appearance="spaced" space="sm")
      cell(cols="narrow" v-for="language in props.languages" :key="language.id")
        ds-link.language-selector.language-selector__link(
          :class="{'is-active': props.value == language.id}"
          @click="props.value !== language.id && data.on.input && data.on.input(language.id)"
        ) {{ language.caption }}
</template>

<script>

export default {
  name: 'language-selector',

  props: {
    url: String,
    value: String,
    languages: Array,
  },
};

</script>
