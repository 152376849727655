import { route } from '@md/routing';

const Scan = () => import('./views/Scan');

const SCANNING_PARCELS = 'cabinet:delivery:scanning';

export function createRoutes() {
  return [
    route('', Scan, SCANNING_PARCELS),
  ];
}
