import { route } from '@md/routing';
import { accessPermission } from '@md/users/permissions';
import {
  SCAN_SHEET_VIEW_LIST,
} from '@md/novaposhta/accessTokens';

const List = () => import('./views/List');

const SCAN_SHEET_PREFIX = 'cabinet:novaposhta:scan-sheets:';
const p = name => SCAN_SHEET_PREFIX + name;
const LIST = p('list');

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: accessPermission(SCAN_SHEET_VIEW_LIST, options),
    }),

    { path: '', redirect: { name: LIST } },
  ];
}
