/* eslint-disable no-empty */
/* eslint-disable keyword-spacing */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable prefer-template */
/* eslint-disable prefer-arrow-callback */
import { isEmpty } from 'ramda';

export function b64EncodeUnicode(str) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str)
      .replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
          return String.fromCharCode('0x' + p1);
        })
  );
}

export function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

export function parametersTo({ filters, offset, ...rest }) {
  if (!offset) {
    offset = undefined;
  }

  return { ...rest, offset, filters: b64EncodeUnicode(JSON.stringify(filters || {})) };
}

export function parametersFrom({ filters, ...rest }) {
  let data = {};

  try {
    data = JSON.parse(b64DecodeUnicode(filters));
  } catch(e) {}

  return { ...rest, filters: isEmpty(data) && filters !== 'e30=' ? filters : data };
}
