import './public-path';

import Vue from 'vue';
import Router from 'vue-router';
import I18n from 'vue-i18n';
import { Store } from 'vuex';

import { extend } from 'vee-validate';
import { install } from '@/install';
import {
  required, confirmed, email, min, min_value, max, max_value, ext, size, integer,
} from 'vee-validate/dist/rules';
import { createApp } from '@/app';

import './styles/index.sass';

// Vue.config.devtools = false;

Vue.config.productionTip = false;

Vue.use(install);

const { app, i18n } = createApp({
  Vue, Router, I18n, Store,
});

function _() {
  return i18n.t.apply(i18n, arguments);
}

extend('required_map', {
  validate(value, keys) {
    const invalid = keys.some(x => !required.validate(value && value[x]).valid);

    return { valid: !invalid, required: min.required || max.required };
  },
  computesRequired: true,
  message: app._('This field is required.'),
});
extend('required', { ...required, message: _('This field is required.') });
extend('confirmed', { ...confirmed, message: _('Passwords must match.') });
extend('email', { ...email, message: _('Email is invalid.') });
extend('min', { ...min, message: _('Value is too short.') });
extend('min_value', { ...min_value, message: _('Minimum value is {min}.') });
extend('max', { ...max, message: _('Value is too long.') });
extend('ext', { ...ext, message: _('Invalid file format') });
// extend('size', { ...size, message: _('Check allow size') });
extend('max_value', { ...max_value, message: _('Maximum value is {max}.') });
extend('integer', { ...integer, message: _('The field must be an integer.') });

app.$mount('#app');
