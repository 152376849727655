import '@md/vendor/promise';

import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';
import Vuex from 'vuex';

import VueMultiselect from 'vue-multiselect';
import VueObserveVisibility from 'vue-observe-visibility';
import ClickOutside from 'vue-click-outside';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import VueDatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
// import VueSlickCarousel from 'vue-slick-carousel';
// import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import VueToast from 'vue-toast-notification';
import VModal from 'vue-js-modal';
import VueMask from 'vue-the-mask';
// import VueCompositionAPI from '@vue/composition-api';

import VueTag from '@aspectus/vue-tag';
import VueGrid from '@aspectus/vue-grid';
import VueBem from '@aspectus/vue-bem';
import VueDermis from '@aspectus/vue-dermis';
import VueDropzone from '@aspectus/vue-dropzone';
import VueRouterParametersController from '@aspectus/vue-router-parameters-controller';
import VuePermissions from '@aspectus/vue-permissions';
import VueRouterPermissions from '@aspectus/vue-router-permissions';
import VueAllSelectionController from '@aspectus/vue-all-selection-controller';
import VueSelectionController from '@aspectus/vue-selection-controller';
import VueOrderingController from '@aspectus/vue-ordering-controller';
import VueTransitionsCollection from '@aspectus/vue-transitions-collection';
import VueRenderSlot from '@aspectus/vue-render-slot';
import VueDropzoneUploader from '@aspectus/vue-dropzone-uploader';
import VueDrawer from '@aspectus/vue-drawer';
import VeeControlDescriptorPlugin from '@aspectus/vee-control-descriptor';
import ControlHintPlugin from '@aspectus/vue-control-hint';
import ControlLabelPlugin from '@aspectus/vue-control-label';
import VueTabs from '@aspectus/vue-tabs';
import VueTable from '@aspectus/vue-table';
import VueDataDependenciesResolver from '@aspectus/vue-data-dependencies-resolver';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import Filters from '@md/filters';
import Resources from '@md/resources';
import UI from '@md/ui';
import Users from '@md/users';

import Api from './api';

import('vue2-datepicker/locale/en');
import('vue2-datepicker/locale/ru');

export function install(Vue) {
  Vue.use(VueRouter);
  Vue.use(VueI18n);
  Vue.use(Vuex);
  // Vue.use(VueCompositionAPI);

  Vue.component('vue-multiselect', VueMultiselect);
  Vue.use(VueObserveVisibility);
  Vue.directive('click-outside', ClickOutside);
  // Vue.component('vue-slick-carousel', VueSlickCarousel);
  Vue.component('vue-slider', VueSlider);
  Vue.component('vue-date-picker', VueDatePicker);
  Vue.component('validation-provider', ValidationProvider);
  Vue.component('validation-observer', ValidationObserver);
  Vue.use(VueToast);
  Vue.use(VModal, { dynamic: true, dialog: true });
  Vue.use(VueMask);

  Vue.use(VueTag);
  Vue.use(VueBem);
  Vue.use(VueGrid);
  Vue.use(VueDermis);
  Vue.use(VueDropzone);
  Vue.use(VueRouterParametersController);
  Vue.use(VuePermissions);
  Vue.use(VueRouterPermissions);
  Vue.use(VueAllSelectionController);
  Vue.use(VueSelectionController);
  Vue.use(VueOrderingController);
  Vue.use(VueTransitionsCollection);
  Vue.use(VueRenderSlot);
  Vue.use(VueDropzoneUploader);
  Vue.use(VueDrawer);
  Vue.use(VueTabs);
  Vue.use(VueTable);
  Vue.use(VeeControlDescriptorPlugin);
  Vue.use(ControlHintPlugin);
  Vue.use(ControlLabelPlugin);
  Vue.use(VueDataDependenciesResolver);

  Vue.use(UI);
  Vue.use(Filters);
  Vue.use(Resources);
  Vue.use(Users);
  Vue.use(Api);

  // Confirm dialog
  Vue.prototype.$confirm = function confirm(value, actor, text, cancelHandler = () => {}) {
    this.$modal.show('dialog', {
      text,
      buttons: [
        { title: this._('No'), default: true, handler: () => { this.$modal.hide('dialog'); cancelHandler(value); } },
        { title: this._('Yes'), handler: () => { this.$modal.hide('dialog'); actor(value); } },
      ],
    });
  };
}

export default { install };
