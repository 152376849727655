import { mergeContext } from '@aspectus/vue-utils';
import { generateModifierClassNames } from '@aspectus/bem';

const b = 'control-select';

export const normalizeSelectValue = value => (
  Array.isArray(value) || typeof value === 'undefined' || value === null
    ? (value || [])
    : [value]
);

export function getLabel(option, key, customGetter) {
  if (!option) return '';

  if (Object(option) === option) {
    if (customGetter) {
      return customGetter(option);
    }

    return option[key];
  }

  return option;
}

export function multipleLabel(h, context, props) {
  return h(
    'span',
    {
      attrs: {
        class: 'multiselect__single',
      },
    },
    [
      props.values && props.values.length && props.values.map(
        option => getLabel(
          option, context.props.label, context.data.attrs.customLabel
        )
      ).join(', ') || '',
    ]
  );
}

const emptyPlaceholder = {};

export default {
  name: b,
  functional: true,

  props: {
    size: { default: 'md' },
    styling: { default: 'default' },
    variant: { default: 'select' },

    multiple: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    label: {},
    value: {},
    placeholder: { default: () => emptyPlaceholder },
    noOptions: { default: () => emptyPlaceholder },
    selectLabel: { default: '' },
    selectedLabel: { default: '' },
    deselectLabel: { default: '' },
  },

  render(h, context) {
    const {
      size,
      styling: style,
      variant,
      multiple, disabled, readonly,
      label, value,
      placeholder,
      noOptions,
      selectLabel, selectedLabel, deselectLabel,
    } = context.props;
    const { class: cls, staticClass, ...data } = context.data;

    return h(
      'tag',
      {
        class: [cls, b, generateModifierClassNames(b, { multiple, style, size, variant }, '--', '_'), { 'is-readonly': readonly, 'is-disabled': disabled }],
        staticClass,
      },
      [
        multiple && value && value.length && value.length > 1
          ? h('tag', { class: [`${b}__counter`] }, [value.length])
          : null,
        h(
          'vue-multiselect',
          mergeContext(data, {
            class: [`${b}__element`],
            attrs: {
              label,
              value,
              selectLabel,
              selectedLabel,
              deselectLabel,
              placeholder: placeholder === emptyPlaceholder ? context.parent._('Выбрать вариант') : placeholder,
              multiple,
              disabled: readonly || disabled,
              'close-on-select': !multiple,
            },
            on: {
              open: e => data.on && data.on.focus && data.on.focus(e),
              close: e => data.on && data.on.blur && data.on.blur(e),
            },
            scopedSlots: {
              selection: props => (
                props.values && props.values.length && !props.isOpen
                  ? multipleLabel(h, context, props)
                  : null
              ),
              ...context.data.scopedSlots,
              noOptions: context.data.scopedSlots && context.data.scopedSlots.noOptions || (() => (noOptions === emptyPlaceholder ? context.parent._('Список пуст') : noOptions)),
            },
          })
        ),
      ]
    );
  },
};
