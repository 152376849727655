import { withDefaultProps } from '@aspectus/vue-utils';
import { Descriptor, NoneController } from '@aspectus/vee-control-descriptor';
import { CInput, CTextArea, CDatePicker, CPoint, CFile } from './inputs';
import Select from './Select';

const dt = tag => withDefaultProps({
  element: () => tag,
  controllerComponent: () => NoneController,
})(Descriptor);

export const DInput = dt(CInput);
export const DTextArea = dt(CTextArea);
export const DDatePicker = dt(CDatePicker);
export const DPoint = dt(CPoint);
export const DFile = dt(CFile);
export const DSelect = dt(Select);

function install(Vue) {
  Vue.component('d-input', DInput);
  Vue.component('d-textarea', DTextArea);
  Vue.component('d-date-picker', DDatePicker);
  Vue.component('d-point', DPoint);
  Vue.component('d-file', DFile);
  Vue.component('d-select', DSelect);
}

export default { install };
