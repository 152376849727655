import { defaultDataTransformer } from '@aspectus/resource-commons';
import { createReceiver, createSender } from '@md/resources';
import { prefixAPI } from '@md/api';

const r = (tpl, f = createReceiver) => f(prefixAPI(tpl)).transform(defaultDataTransformer);

export const clients = {
  departmentsList: r('/clients/addons/departments/list/{?filters,order_by,limit,offset}'),
  list: r('/clients/list/{?filters,order_by,limit,offset}'),
  aggregate: r('/clients/aggregate/{?filters,order_by,limit,offset}'),
  listLeadPage: r('/clients/lead-page-list/{?filters,order_by,limit,offset}'),
  listExtended: r('/clients/list-extended/{?filters,order_by,limit,offset}'),
  filters: {
    list: r('/clients/filters/list/'),
    available: r('/clients/filters/list/{?filters}'),
  },
  create: r('/clients/create/', createSender),
  retrieve: r('/clients{/id}/retrieve/'),
  update: r('/clients{/id}/update/', createSender).config('method', 'PUT'),
};

export const comments = {
  list: r('/comments/list/{?filters,order_by,limit,offset}'),
  filters: {
    list: r('/comments/filters/list/'),
    available: r('/comments/filters/list/{?filters}'),
  },
  create: r('/comments/create/', createSender),
};

export const complaints = {
  create: r('/complaints/create/', createSender),
};
