import { createStore as authCreateStore } from '@md/auth';

import { userInfoResource, userUpdateResource } from './api';

export function createStore(parameters) {
  return {
    namespaced: true,

    modules: {
      auth: authCreateStore({
        receiveResource: userInfoResource,
        patchResource: userUpdateResource,
        ...parameters,
      }),
    },

    getters: {
      userName: state => {
        if (!state.auth.user.isAuthenticated()) {
          return null;
        }

        const {
          username, profile = {},
        } = state.auth.user.info;

        return profile && profile.displayName || username;
      },
      userType: state => {
        if (!state.auth.user.isAuthenticated()) {
          return null;
        }

        const {
          profile = {},
        } = state.auth.user.info;

        return profile && profile.type;
      },
      clientBalance: state => {
        if (!state.auth.user.isAuthenticated()) {
          return null;
        }

        const {
          balance = null, profile = {},
        } = state.auth.user.info;

        return profile && profile.balance || balance;
      },
      clientManager: state => {
        if (!state.auth.user.isAuthenticated()) {
          return null;
        }

        const {
          manager = null, profile = {},
        } = state.auth.user.info;

        return profile && profile.manager || manager;
      },
      clientOperationsManager: state => {
        if (!state.auth.user.isAuthenticated()) {
          return null;
        }

        const {
          operationsManager = null, profile = {},
        } = state.auth.user.info;

        return profile && profile.operationsManager || operationsManager;
      },
      isMainStockman: state => {
        if (!state.auth.user.isAuthenticated()) {
          return null;
        }

        const {
          isMainStockman = false,
        } = state.auth.user.info.profile;

        return isMainStockman;
      },
      location: state => {
        if (!state.auth.user.isAuthenticated()) {
          return null;
        }

        const {
          location = null, profile = {},
        } = state.auth.user.info;

        return profile && profile.location || location;
      },
    },
  };
}
