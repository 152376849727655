<template>
  <control-select
    :placeholder="placeholder || filter.caption"
    :searchable="false"
    variant="dropdown-wrapper"
    :size="$attrs.size"
    :options="[]"
    :value="selectedRange"
    label="label"
  >
    <template v-slot:noOptions>
      <div>
        {{
          formatTranslation(
            _('От <[start]>(<[min]>) до <[end]>(<[max]>)'),
            { start: internal[0], end: internal[1], min, max }
          )
        }}
      </div>
      <vue-slider
        class="control-slider"
        v-model="internal"
        v-bind="$attrs"
        :clickable="false"
        :drag-on-click="true"
        transition="0.2"
        :interval="interval"
        :min="min"
        :max="max"
        @drag-end="update"
      />
    </template>
  </control-select>
</template>

<script>

import { path } from 'ramda';
import { formatTranslation } from '@md/strings';

const minPath = path(['props', 'min']);
const maxPath = path(['props', 'max']);

const gcd = (a, b, onZero = 1) => (
  a < b
    ? gcd(b, a, onZero)
    : (
      b < 0.001 || isNaN(b) || isNaN(b)
        ? (b === 0 ? onZero : a)
        : gcd(b, a - Math.floor(a / b) * b)
    )
);

export default {
  name: 'filter-slider-widget',
  props: ['value', 'filter', 'available', 'placeholder'],

  data() {
    return {
      internal: [0, 1],
      interval: 1,
      min: 0,
      max: 1,
    };
  },

  watch: {
    filter: { deep: true, immediate: true, handler: 'clearMinMax' },
    available: { deep: true, handler: 'clearMinMax' },
    value: { immediate: true, handler: 'updateInternal' },
  },
  computed: {
    selectedRange() {
      if (this.internal[0] === this.min && this.internal[1] === this.max) {
        return '';
      }
      return {
        label: `${this.placeholder || this.filter.caption}: ${this.internal[0]}-${this.internal[1]}`,
      };
    },
  },

  methods: {
    formatTranslation,

    clearMinMax() {
      this.min = parseFloat(
        Math.max(minPath(this.available) || 0, minPath(this.filter) || 0).toFixed(2)
      );
      this.max = parseFloat(
        Math.min(maxPath(this.available) || 0, maxPath(this.filter) || 0).toFixed(2)
      );
      this.interval = parseFloat(gcd(Math.abs(this.min), Math.abs(this.max)).toFixed(2));

      this.updateInternal();
    },

    updateInternal(value = this.value) {
      this.internal = this.normalize(value);
    },

    update() {
      this.input(this.internal);
    },

    input(value) {
      this.$emit('input', this.clear(value));
    },

    clear(value) {
      let [min, max] = value;
      [min, max] = this.normalize({ min, max });
      if (min === this.min && max === this.max) {
        return undefined;
      }

      const result = {};

      if (min !== this.min) {
        result.min = min;
      }

      if (max !== this.max) {
        result.max = max;
      }

      return result;
    },

    normalize(value) {
      if (!value) {
        return [this.min, this.max];
      }

      const { min = this.min, max = this.max } = value;

      return [Math.max(this.min, min), Math.min(this.max, max)];
    },
  },
};

</script>
