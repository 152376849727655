<template lang="pug">

row(
  :appearance="['inline', 'nowrap']", class="vue-table-ordering",
  @click.prevent="change", space="xs", align="center"
)
  cell(cols="auto")
    slot
  cell(cols="narrow")
    icon(:name="$options.ICONS[this.direction]", size="md")
    sup(v-if="index !== -1", style="line-height: 1px") {{ index + 1 }}

</template>

<script>

export default {
  props: ['controller', 'field'],
  ICONS: { null: 'unfold_more', asc: 'arrow_downward', desc: 'arrow_upward' },
  computed: {
    value() {
      return this.controller.valueMap[this.field.key] || { field: this.field.key, direction: null };
    },
    direction() {
      return this.controller.directionGetter(this.value);
    },
    index() {
      return this.value.direction === null ? -1 : this.controller.value.findIndex(x => x.field === this.field.key);
    },
  },
  methods: {
    change() {
      this.controller.change(this.value);
    },
  },
};

</script>
