<template functional lang="pug">

include /mixins.pug

+b.TAG(
  v-bem:[props.blockName]="{ styling: props.styling, size: props.size, kind: props.kind, variant: props.variant }"
  v-state="{ disabled: props.disabled, readonly: props.readonly, invalid: props.invalid }"
  :class="[data.staticClass, data.class]"
  :tag="props.tag"
)
  div(v-bem:[props.blockName].body)
    slot(name="prepend")
    +e.TAG(
      v-bem:[props.blockName].element="{ kind: 'input', primary: true }"
      :enable-vue-tag-props-fix="true",
      :readonly="props.readonly",
      :disabled="props.disabled",
      :tag="props.innerElement",
      v-bind="data.attrs",
      v-on="data.on",
      v-if="!props.mask"
    )
    +e.THE-MASK(
      v-bem:[props.blockName].element="{ kind: 'input', primary: true }"
      :enable-vue-tag-props-fix="true",
      :readonly="props.readonly",
      :disabled="props.disabled",
      :tag="props.innerElement",
      :mask="props.mask",
      masked="",
      v-bind="data.attrs",
      v-on="data.on",
      v-else
    )
    slot(name="append")
    div(
      v-bem:[props.blockName].element="{ kind: 'icon', append: true }",
      v-if="props.icon || scopedSlots.icon"
    )
      slot(name="icon")
        icon(:name="props.icon")

</template>

<script>

export default {
  name: 'control-input-element',
  props: {
    blockName: String,
    innerElement: {},

    kind: {},
    variant: {},
    size: { default: 'md' },
    styling: { default: 'default' },
    disabled: Boolean,
    readonly: Boolean,
    invalid: Boolean,
    mask: String,
    tag: { default: 'label' },
    icon: {},
  },
};

</script>
