import { path } from 'ramda';
import { ValueResolver, ResourceController } from './controllers';

const defaultTransformer = e => e;
const dependencyFieldGetter = path(['field']);

export const createDescriptor = (source, dependencies, controller) => ({
  source, dependencies, controller,
});

// export const createControlledDescriptor = (
//   source, dependencies, options = {}, Base = ResourceController
// ) => createDescriptor(
//   source,
//   dependencies.map(dependencyFieldGetter),
//   new Base(Object.assign({
//     valueResolver: new ValueResolver(dependencies),
//   }, options))
// );

// export const createField = (field, transform = defaultTransformer) => ({ field, transform });
