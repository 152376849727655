import Tabs from './Tabs';
import Tab from './Tab';

function install(Vue, {
  tabsName = Tabs.name,
  tabName = Tab.name,
} = {}) {
  Vue.component(tabsName, Tabs);
  Vue.component(tabName, Tab);
}

export { Tabs, Tab };
export default { install };
export * from './tags';
export * from './utils';
