<template>
  <filter-controller
    :receive="allProps.receive"
    :receiveAvailable="availableProps.receive"
    :all="allProps.result && allProps.result.items"
    :available="availableProps.result && availableProps.result.items"
    v-bind="$attrs"

    :loading="allProps.loading || availableProps.loading"
    :value="value"
    :permanent="permanent"
    @input="handleFilter"
  />
</template>

<script>

import FilterController from './Controller';

export default {
  props: ['value', 'allProps', 'availableProps', 'permanent'],

  components: {
    FilterController,
  },

  methods: {
    handleFilter(parameters) {
      this.$emit('input', parameters);
    },
  },
};

</script>
