import { renderSlim } from '@aspectus/vue-utils';
import { pick, omit } from 'ramda';
import { AggregateLoaderMixin } from './mixins';

function orderingTo(ordering) {
  if (!(ordering || ordering.length)) {
    return undefined;
  }
  if (typeof ordering === 'object') {
    return (ordering.direction === 'desc' ? '-' : '') + ordering.field;
  }

  return ordering.map(x => (x.direction === 'desc' ? '-' : '') + x.field).filter(x => !!x).join(',');
}

function parseOrdering(field) {
  const desc = field.startsWith('-');

  if (desc) {
    return { field: field.slice(1), direction: 'desc' };
  }

  return { field, direction: 'asc' };
}

function orderingFrom(ordering) {
  return (ordering || '').split(',').map(parseOrdering);
}

export default {
  name: 'catalog-pagination-controller',
  mixins: [AggregateLoaderMixin],
  props: {
    immediate: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.immediate && this.initialize();
  },

  computed: {
    ordering() {
      return orderingFrom(this.parameters?.order_by || null);
    },
  },
  methods: {
    initialize() {
      this.receive(this.parameters);
      this.aggregateResource && this.receiveAggregate(this.parameters);
    },
    changeOrdering(ordering) {
      this.parameters.order_by = orderingTo(ordering);
      this.parametersUpdate(omit(this.filterPaginationDropKeys, {
        ...this.filters,
        ...pick(this.paginationKeys, this.pagination),
        ...pick(this.onlyParametersKeys, this.parameters),
      }));
    },
  },
  render(h) {
    if (!this.$scopedSlots.default) {
      return null;
    }

    return renderSlim(this.$scopedSlots.default({
      parameters: this.parameters,
      ordering: this.ordering,
      filters: this.filters,
      changeFilters: this.changeFilters,
      pagination: this.pagination,
      changePagination: this.changePagination,
      changeOrdering: this.changeOrdering,
      receive: this.receive,
      receiveAggregate: this.receiveAggregate,
      loading: this.loading,
      result: this.result,
      aggregate: this.aggregate,
    }), h, 'tag');
  },
};
