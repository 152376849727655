import {
  TRANSACTIONS_BAR_VIEW_LIST,
  SALARY_VIEW_LIST,
  CASHBOX_VIEW_LIST,
  PAYMENTS_TRANSFERS_VIEW_LIST,
} from '@md/finances/accessTokens';
import { prefixRoutes } from '@md/routing';
import { accessPermission } from '@md/users/permissions';

import { createRoutes as createTransactionsRoutes } from './modules/transactions/router';
import { createRoutes as createSalaryRoutes } from './modules/salaries/router';
import { createRoutes as createCashboxRoutes } from './modules/cashbox/router';
import { createRoutes as createPaymentTransfersRoutes } from './modules/transfers/router';

export function createRoutes(options) {
  return [
    prefixRoutes('transactions/', createTransactionsRoutes(options), {
      meta: accessPermission(TRANSACTIONS_BAR_VIEW_LIST, options),
    }),
    prefixRoutes('salaries/', createSalaryRoutes(options), {
      meta: accessPermission(SALARY_VIEW_LIST, options),
    }),
    prefixRoutes('cashbox/', createCashboxRoutes(options), {
      meta: accessPermission(CASHBOX_VIEW_LIST, options),
    }),
    prefixRoutes('transfers/', createPaymentTransfersRoutes(options), {
      meta: accessPermission(PAYMENTS_TRANSFERS_VIEW_LIST, options),
    }),
    { path: '', redirect: 'cashbox/' },
  ];
}
