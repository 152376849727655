import { mergeContext } from '@aspectus/vue-utils';
import { TabTag } from './tags';
import { statefulContext } from './utils';

export default {
  render(h) {
    return h(
      TabTag,
      mergeContext(statefulContext(this), { role: 'tabpanel' }),
      this.$slots.default
    );
  },
  props: { label: String, labelSlot: String, disabled: Boolean, props: Object },
  name: 'vue-tabs-tab',
};
