<template lang="pug">

include /mixins.pug

router-parameters-controller(
  @history-change="manualHistoryChangeHandler",
  :initial="initial",
  :to="parametersTo",
  :from="parametersFrom",
  v-slot="{ parameters, changeParameters, receive }"
)
  component(
    :is="controllerComponent",
    :parameters="parameters",
    @update:parameters="changeParameters",
    ref="controller",
    :receive='receive'
    v-bind="$attrs",
    v-slot="receivedParameters"
  )
    slot(v-bind="receivedParameters")

</template>

<script>

import { parametersTo, parametersFrom } from './utils';
import Controller from './Controller';

export default {
  name: 'catalog-page-filtering',
  inheritAttrs: false,
  props: {
    initial: Object,
    controllerComponent: { default: () => Controller },
  },

  methods: {
    parametersTo,
    parametersFrom,

    manualHistoryChangeHandler() {
      if (this.$refs.controller) {
        this.$nextTick(this.$refs.controller.initialize);
      }
    },
  },
};

</script>
