<template functional lang="pug">

include /mixins.pug

+b.IMG.logo(
  :class="[data.class, data.staticClass]",
  v-bind="data.attrs",
  v-on="data.listeners",
  src="@/assets/logo.png"
)

</template>

<script>

export default {
  name: 'logo',
};

</script>
