import Logo from './Logo';
import InfoPageContainer from './InfoPageContainer';
import Icon from './Icon';
import SVGIcon from './SVGIcon';
import LanguageSelector from './LanguageSelector';

import LocationLink from './LocationLink';
import PositionsInfo from './PositionsInfo';
import StatusesInfo from './StatusesInfo';
import TableItemLink from './TableItemLink';
import TableItemPdf from './TableItemPdf';
import TableItemTruncate from './TableItemTruncate';

import FileTemplate from './FileTemplate';
import FilePreview from './FilePreview';
import FileUploader from './FileUploader';

import LoadingWrapper from './LoadingWrapper';
import Loading from './Loading';
import ReadMore from './ReadMore';
import ModalTrigger from './ModalTrigger';
import ViewerImage from './ViewerImage';

const Lightbox = () => import('./Lightbox.vue');

function install(Vue) {
  Vue.component(Logo.name, Logo);

  Vue.component(LoadingWrapper.name, LoadingWrapper);
  Vue.component(Loading.name, Loading);
  Vue.component(ReadMore.name, ReadMore);
  Vue.component(ModalTrigger.name, ModalTrigger);
  Vue.component(ViewerImage.name, ViewerImage);
  Vue.component(SVGIcon.name, SVGIcon);
  Vue.component(LanguageSelector.name, LanguageSelector);

  Vue.component('file-template', FileTemplate);
  Vue.component(FilePreview.name, FilePreview);
  Vue.component(FileUploader.name, FileUploader);

  Vue.component('lightbox', Lightbox);
  Vue.component('info-page-container', InfoPageContainer);
  Vue.component('table-item-link', TableItemLink);
  Vue.component('table-item-pdf', TableItemPdf);
  Vue.component('table-item-truncate', TableItemTruncate);
  Vue.component('location-link', LocationLink);
  Vue.component('statuses-info', StatusesInfo);
  Vue.component('positions-info', PositionsInfo);
  Vue.component('icon', Icon);
}

export default { install };
