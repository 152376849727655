<template lang="pug">

router-view(
  :result="result", :loadingResult="loading",
  :updateResult="update", :changeResult="change"
)

</template>

<script>

import { ResourceLoaderMixin } from '@md/ui/catalog/mixins';

export default {
  mixins: [ResourceLoaderMixin],
  created() {
    this.update();
  },
  methods: {
    update() {
      this.receive({ id: this.$route.params.id });
    },
    change(data) {
      this.result = data;
    },
  },
};

</script>
