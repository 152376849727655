import { route, prefixRoutes } from '@md/routing';
import { staff } from '@md/staff/api';
import { accessPermission } from '@md/users/permissions';
import {
  STAFF_VIEW_LIST,
  STAFF_EDIT,
  STAFF_CREATE,
} from '@md/staff/accessTokens';
import { receiveRoute } from '@sections/cabinet/components/views/utils';

const List = () => import('./views/List');
const EditManager = () => import('./views/EditManager');
const EditCashbox = () => import('./views/EditCashbox');
const EditTransfer = () => import('./views/EditTransferType');
const EditAccountant = () => import('./views/EditAccountantType');
const EditOperationsManager = () => import('./views/EditOperationsManager');
const EditAssistant = () => import('./views/EditAssistant');
const EditStockman = () => import('./views/EditStockman');
const Create = () => import('./views/Create');

const STAFF_PREFIX = 'cabinet:staff:';
const p = name => STAFF_PREFIX + name;
export const LIST = p('list');
export const CREATE = p('create');
export const EDIT_MANAGER = p('manager:edit');
export const EDIT_OPERATIONS_MANAGER = p('operations-manager:edit');
export const EDIT_ASSISTANT = p('assistant:edit');
export const EDIT_STOCKMAN = p('stockman:edit');
export const EDIT_CASHBOX_USER_TYPE = p('cashbox-type:edit');
export const EDIT_TRANSFER_USER_TYPE = p('transfer-type:edit');
export const EDIT_ACCOUNTANT_USER_TYPE = p('accountant-type:edit');

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: accessPermission(STAFF_VIEW_LIST, options),
    }),
    route('create/', Create, CREATE, {
      meta: accessPermission(STAFF_CREATE, options),
    }),
    prefixRoutes('stockman/', [
      receiveRoute(staff.stockmen.retrieve, [
        route('edit/', EditStockman, EDIT_STOCKMAN, {
        // route('edit/', List, EDIT, {
          meta: accessPermission(STAFF_EDIT, options),
        }),
        { path: '', redirect: { name: EDIT_STOCKMAN } },
      ]),
    ]),
    prefixRoutes('manager/', [
      receiveRoute(staff.managers.retrieve, [
        route('edit/', EditManager, EDIT_MANAGER, {
          // route('edit/', List, EDIT, {
          meta: accessPermission(STAFF_EDIT, options),
        }),
        { path: '', redirect: { name: EDIT_MANAGER } },
      ]),
    ]),
    prefixRoutes('operations-manager/', [
      receiveRoute(staff.operationsManagers.retrieve, [
        route('edit/', EditOperationsManager, EDIT_OPERATIONS_MANAGER, {
          // route('edit/', List, EDIT, {
          meta: accessPermission(STAFF_EDIT, options),
        }),
        { path: '', redirect: { name: EDIT_OPERATIONS_MANAGER } },
      ]),
    ]),
    prefixRoutes('cashbox-type/', [
      receiveRoute(staff.cashboxType.retrieve, [
        route('edit/', EditCashbox, EDIT_CASHBOX_USER_TYPE, {
          // route('edit/', List, EDIT, {
          meta: accessPermission(STAFF_EDIT, options),
        }),
        { path: '', redirect: { name: EDIT_CASHBOX_USER_TYPE } },
      ]),
    ]),
    prefixRoutes('transfer-type/', [
      receiveRoute(staff.transferType.retrieve, [
        route('edit/', EditTransfer, EDIT_TRANSFER_USER_TYPE, {
          // route('edit/', List, EDIT, {
          meta: accessPermission(STAFF_EDIT, options),
        }),
        { path: '', redirect: { name: EDIT_TRANSFER_USER_TYPE } },
      ]),
    ]),
    prefixRoutes('accountant-type/', [
      receiveRoute(staff.accountantType.retrieve, [
        route('edit/', EditAccountant, EDIT_ACCOUNTANT_USER_TYPE, {
          // route('edit/', List, EDIT, {
          meta: accessPermission(STAFF_EDIT, options),
        }),
        { path: '', redirect: { name: EDIT_ACCOUNTANT_USER_TYPE } },
      ]),
    ]),
    prefixRoutes('assistant/', [
      receiveRoute(staff.assistants.retrieve, [
        route('edit/', EditAssistant, EDIT_ASSISTANT, {
          // route('edit/', List, EDIT, {
          meta: accessPermission(STAFF_EDIT, options),
        }),
        { path: '', redirect: { name: EDIT_ASSISTANT } },
      ]),
    ]),

    { path: '', redirect: { name: LIST } },
  ];
}
