import { route } from '@md/routing';
import { shipments } from '@md/delivery/api';
import { accessPermission } from '@md/users/permissions';
import {
  SHIPMENTS_VIEW_LIST,
  SHIPMENTS_VIEW,
  SHIPMENTS_EDIT,
  SHIPMENTS_CREATE,
} from '@md/delivery/accessTokens';
import { receiveRoute } from '@sections/cabinet/components/views/utils';

const List = () => import('./views/List');
// const Preview = () => import('./views/Preview');
const Edit = () => import('./views/Edit/View');
const Create = () => import('./views/Create');

const SHIPMENTS_PREFIX = 'cabinet:delivery:shipments:';
const p = name => SHIPMENTS_PREFIX + name;
const LIST = p('list');
const EDIT = p('edit');

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: accessPermission(SHIPMENTS_VIEW_LIST, options),
    }),
    // route(':id/preview/', Preview, p('preview'), {
    //   meta: accessPermission(SHIPMENTS_VIEW, options),
    // }),
    receiveRoute(shipments.retrieve, [
      route('edit/', Edit, EDIT, {
        meta: accessPermission(SHIPMENTS_EDIT, options),
      }),
      { path: '', redirect: { name: EDIT } },
    ]),
    // route(':id/edit/', Edit, p('edit'), {
    //   meta: accessPermission(SHIPMENTS_EDIT, options),
    // }),
    route('create/', Create, p('create'), {
      meta: accessPermission(SHIPMENTS_CREATE, options),
    }),

    { path: '', redirect: { name: LIST } },
  ];
}
