/* eslint-disable prefer-object-spread */

export const {
  MAP,
  MANAGER_TYPE,
  OPERATIONS_MANAGER_TYPE,
  ASSISTANT_TYPE, STOCKMAN_TYPE,
  CLIENT_TYPE,
  CASHBOX_USER_TYPE,
  TRANSFER_USER_TYPE,
  ACCOUNTANT_USER_TYPE,
  ALL: TOKENS,
} = global.ACCESS_TOKENS;
