import { route } from '@md/routing';

const View = () => import('./index');

const TRANSACTION = 'cabinet:transaction:';

export function createRoutes() {
  return [
    route('', View, TRANSACTION),

    { path: '', redirect: { name: TRANSACTION } },
  ];
}
