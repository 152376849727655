import { path, curry } from 'ramda';
import { mergeContext, withDefaultProps } from '@aspectus/vue-utils';
import { toArray } from '@aspectus/selection-controller';
import {
  DInput, DTextArea, DDatePicker, DPoint, DSelect, DFile,
} from './described';

const apply = curry((path, object, value) => {
  const obj = object;
  let last = null;

  path.forEach(key => {
    obj[key] = obj[key] || {};
    last = key;
  });

  obj[last] = value;
});

const Field = {
  functional: true,
  props: ['innerElement', 'name', 'data', 'superLabel'],
  render(h, context) {
    const { innerElement, name, data, superLabel: s } = context.props;
    const p = toArray(name);
    const labels = s ? { labelText: s, placeholder: s } : {};

    return h(
      innerElement,
      mergeContext(context.data, {
        attrs: Object.assign(labels, { name: p.join('.'), value: path(p, data) }),
        on: { input: apply(p, data) },
      }),
      context.children
    );
  },
};

const t = tag => withDefaultProps({ innerElement: () => tag })(Field);

export const FInput = t(DInput);
export const FTextArea = t(DTextArea);
export const FDatePicker = t(DDatePicker);
export const FPoint = t(DPoint);
export const FFile = t(DFile);
export const FSelect = t(DSelect);

function install(Vue) {
  Vue.component('f-input', FInput);
  Vue.component('f-textarea', FTextArea);
  Vue.component('f-date-picker', FDatePicker);
  Vue.component('f-point', FPoint);
  Vue.component('f-file', FFile);
  Vue.component('f-select', FSelect);
}

export default { install };
