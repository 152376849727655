import { accessGuard } from '@aspectus/vue-router-permissions';
import { prefixRoutes, route } from '@md/routing';
import { prefixLanguage } from '@md/urls';
import { createRoutes as createAuthRoutes } from '@sections/auth/router';
import { createRoutes as createCabinetRoutes } from '@sections/cabinet/router';
import Route404 from '@sections/404';

export function createRoutes(options) {
  return [
    prefixRoutes(prefixLanguage('/auth/'), createAuthRoutes(options)),
    prefixRoutes(prefixLanguage('/cabinet/'), createCabinetRoutes(options)),
    { path: '', redirect: '/cabinet/' },

    route('/404/', Route404, '404'),
    { path: '*', redirect: { name: '404' } },
  ];
}

export function scrollBehavior(to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  }

  return {
    x: window.pageXOffset,
    y: window.pageYOffset,
  };
}

export function createRouter(options = {}) {
  const { Router, Vue } = options;
  const router = new Router({
    mode: 'history',
    base: '/',
    scrollBehavior,
    routes: createRoutes({ Router, Vue }),
  });

  router.beforeEach(accessGuard(options));

  return router;
}
