import { route } from '@md/routing';
import { localDeliveryApi } from '@md/transfers/api';
import { accessPermission } from '@md/users/permissions';
import {
  LOCAL_DELIVERY_VIEW_LIST,
  LOCAL_DELIVERY_EDIT,
  LOCAL_DELIVERY_CREATE,
} from '@md/transfers/accessTokens';
import { receiveRoute } from '@sections/cabinet/components/views/utils';

const List = () => import('./views/List');
const Edit = () => import('./views/Edit');
const Create = () => import('./views/Create');

const LOC_DELIVERY_PREFIX = 'cabinet:loc-delivery:';
const p = name => LOC_DELIVERY_PREFIX + name;
export const LIST = p('list');
export const EDIT = p('edit');
export const CREATE = p('create');

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: accessPermission(LOCAL_DELIVERY_VIEW_LIST, options),
    }),
    receiveRoute(localDeliveryApi.retrieve, [
      route('edit/', Edit, EDIT, {
        meta: accessPermission(LOCAL_DELIVERY_EDIT, options),
      }),
      { path: '', redirect: { name: EDIT } },
    ]),
    route('create/', Create, CREATE, {
      meta: accessPermission(LOCAL_DELIVERY_CREATE, options),
    }),

    { path: '', redirect: { name: LIST } },
  ];
}
