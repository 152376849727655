import {
  SCAN_SHEET_VIEW_LIST,
  DOCUMENTS_VIEW_LIST,

} from '@md/novaposhta/accessTokens';
import { prefixRoutes } from '@md/routing';
import { accessPermission } from '@md/users/permissions';

import { createRoutes as createScanSheetRoutes } from './modules/scan-sheet/router';
import { createRoutes as createDocumentsRoutes } from './modules/documents/router';

export function createRoutes(options) {
  return [
    prefixRoutes('scan-sheets/', createScanSheetRoutes(options), {
      meta: accessPermission(SCAN_SHEET_VIEW_LIST, options),
    }),
    prefixRoutes('documents/', createDocumentsRoutes(options), {
      meta: accessPermission(DOCUMENTS_VIEW_LIST, options),
    }),
    { path: '', redirect: 'documents/' },
  ];
}
