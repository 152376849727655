import { defaultDataTransformer } from '@aspectus/resource-commons';
import { createReceiver, createSender } from '@md/resources';
import { prefixAPI } from '@md/api';

const r = (tpl, f = createReceiver) => f(prefixAPI(tpl)).transform(defaultDataTransformer);

export const orders = {
  list: r('/orders/list/{?filters,order_by,limit,offset}'),
  filters: {
    list: r('/orders/filters/list/'),
    available: r('/orders/filters/list/{?filters}'),
  },
  addons: {
    statuses: r('/orders/addons/statuses/'),
  },
  create: r('/orders/create/', createSender),
  retrieve: r('/orders{/id}/retrieve/'),
  update: r('/orders{/id}/update/', createSender).config('method', 'PUT'),
};
